import React, { useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  useNavigate,
  useLocation,
  Outlet,
  useParams
} from 'react-router-dom';
import Verification from '../../classes/verification';
import Survey from '../../classes/survey';

import './InspectionLayout.scss';

import InspectionSideNav from './InspectionSideNav';
import InspectionFooter from './InspectionFooter';
import { InspectionContext } from '../../context/InspectionContext';
import { Inspection } from '../../classes/inspection';

type Parts = 'site-details' | 'inspection-details' | 'leak-details' | 'inspection-summary' | 'final-submission';

const InspectionLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { pathname } = location;
  const {
    inspection,
    token,
    setInspection,
    getLocalInspectionByIdAndUpdate
  } = useContext(InspectionContext);

  const pathParts = pathname.split('/').filter((part) => part);

  let currentSection: Parts | undefined;
  let currentPart: number | undefined;

  if (pathParts.length >= 4) {
    currentSection = pathParts[2] as Parts;
    currentPart = parseInt(pathParts[3], 10);
  } else {
    currentSection = pathParts.at(-1) as Parts;
    currentPart = parseInt(pathParts[2], 10);
  }

  const generateNextRoutes = (inspectionId: string) => {
    const nextRoutes: Record<Parts, string> = {
      'site-details': currentPart! < 2
        ? `/inspections/${inspectionId}/site-details/${currentPart! + 1}`
        : `/inspections/${inspectionId}/inspection-details/1`,
      'inspection-details': currentPart! < 4
        ? `/inspections/${inspectionId}/inspection-details/${currentPart! + 1}`
        : `/inspections/${inspectionId}/leak-details/1`,
      'leak-details': currentPart! < 2
        ? `/inspections/${inspectionId}/leak-details/${currentPart! + 1}`
        : `/inspections/${inspectionId}/inspection-summary/1`,
      'inspection-summary': currentPart! < 2
        ? `/inspections/${inspectionId}/inspection-summary/${currentPart! + 1}`
        : `/inspections/${inspectionId}/final-submission`,
      'final-submission': `/inspections/${inspectionId}/submit`
    };
    return nextRoutes;
  };

  const generatePrevRoutes = (inspectionId: string) => {
    const prevRoutes: Record<Parts, string> = {
      'site-details': currentPart! > 1
        ? `/inspections/${inspectionId}/site-details/${currentPart! - 1}`
        : '',
      'inspection-details': currentPart! > 1
        ? `/inspections/${inspectionId}/inspection-details/${currentPart! - 1}`
        : `/inspections/${inspectionId}/site-details/2`,
      'leak-details': currentPart! > 1
        ? `/inspections/${inspectionId}/leak-details/${currentPart! - 1}`
        : `/inspections/${inspectionId}/inspection-details/4`,
      'inspection-summary': currentPart! > 1
        ? `/inspections/${inspectionId}/inspection-summary/${currentPart! - 1}`
        : `/inspections/${inspectionId}/leak-details/2`,
      'final-submission': `/inspections/${inspectionId}/inspection-summary/2`
    };
    return prevRoutes;
  };

  const handleNavigation = () => {
    const nextRoutes = generateNextRoutes(inspection!.id ?? '');
    const nextPath = currentSection ? nextRoutes[currentSection] : undefined;
    if (nextPath) {
      navigate(nextPath);
    }
  };

  const handleBack = () => {
    const prevRoutes = generatePrevRoutes(inspection!.id ?? '');
    const prevPath = currentSection ? prevRoutes[currentSection] : undefined;
    if (prevPath) {
      navigate(prevPath);
    }
  };

  const hasBackButton = currentSection !== 'site-details' || currentPart !== 1;
  const hasNextButton = currentSection !== 'final-submission';

  useEffect(() => {
    if (!inspection?.id && !id) {
      const inspectionId = uuidv4();
      const newInspection = new Inspection({ id: inspectionId } as Inspection);
      navigate(`/inspections/${inspectionId}/site-details/1`);
      const newSurvey = new Survey({ surveyId: uuidv4() });
      const newVerification = new Verification({
        verificationId: uuidv4()
      });
      newInspection!.addVerification(token, newVerification);
      newInspection!.addSurvey(token, newSurvey);
      setInspection(newInspection);
    }
    if (!inspection?.id && id) {
      getLocalInspectionByIdAndUpdate(id);
    }
  }, [inspection?.id, id]);

  return (
    !inspection || (inspection?.id !== id)
      ? <div>Loading...</div>
      : (
        <div className="new-modify-inspection">
          <InspectionSideNav currentSection={currentSection} currentPart={currentPart} />
          <main>
            <section className="border">
              <section className="scroll-right">
                <div className="page-details">
                  <Outlet />
                </div>
              </section>
              <InspectionFooter
                hasBackButton={hasBackButton}
                hasNextButton={hasNextButton}
                handleBack={handleBack}
                handleNavigation={handleNavigation}
              />
            </section>
          </main>
        </div>
      )
  );
};

export default InspectionLayout;
