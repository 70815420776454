import React, {
  useState,
  createContext,
  useEffect,
  useMemo,
  ReactNode
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchFilesForInspection } from '../services/airmethaneApi';
import { getLocalInspectionById } from '../dexie/operations';
import { Inspection } from '../classes/inspection';
import Verification, { IVerification } from '../classes/verification';
import Survey, { ISurvey } from '../classes/survey';
import { Leak, ILeak } from '../classes/leak';
import MonitoredComponent, { IMonitoredComponent } from '../classes/monitored-component';

interface InspectionContextShape {
  inspection: Inspection | null;
  token: string;
  setInspection: (inspection: Inspection) => void;
  completedSections: string[];
  setCompletedSectionsOnChange: () => void;
  fileOptions: { label: string, value: string, fileUrl: string, fileType: string, content?: React.ReactNode }[];
  setFileOptions: (options: {
    label: string, value: string, fileUrl: string, fileType: string, content?: React.ReactNode
  }[]) => void;
  hasFileId: (fileId: string) => boolean;
  getLocalInspectionByIdAndUpdate: (inspectionId: string) => Promise<void>;
  hasAllRequiredFields: boolean;
  setHasAllRequiredFields: (value: boolean) => void;
}

const defaultInspectionContext: InspectionContextShape = {
  inspection: null,
  setInspection: () => {},
  token: '',
  completedSections: [],
  setCompletedSectionsOnChange: () => {},
  fileOptions: [],
  setFileOptions: () => {},
  hasFileId: () => false,
  getLocalInspectionByIdAndUpdate: async () => {},
  hasAllRequiredFields: false,
  setHasAllRequiredFields: () => {}
};

export const InspectionContext = createContext<InspectionContextShape>(defaultInspectionContext);

export const InspectionProvider = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState('');
  const [inspection, setInspection] = useState<Inspection | null>(null);
  const [completedSections, setCompletedSections] = useState<string[]>([]);
  const [locationData, setLocationData] = useState();
  const [hasAllRequiredFields, setHasAllRequiredFields] = useState(false);
  const [fileOptions, setFileOptions] = useState<{
    label: string, value: string, fileUrl: string, fileType: string
  }[]>([]);

  const setCompletedSectionsOnChange = () => {
    const siteDetailsCompleted = inspection?.getSiteDetailCompletionStatus();
    const inspectionDetailsCompleted = inspection?.getInspectionDetailCompletionStatus();
    const leakDetailsCompleted = inspection?.getLeakDetailCompletionStatus();
    const inspectionSummaryCompleted = inspection?.getInspectionSummaryCompletionStatus();
    const finalSubmissionCompleted = inspection?.getFinalSubmissionCompletionStatus();
    const newCompletedSections = [
      siteDetailsCompleted ? 'site-details' : '',
      inspectionDetailsCompleted ? 'inspection-details' : '',
      leakDetailsCompleted ? 'leak-details' : '',
      inspectionSummaryCompleted ? 'inspection-summary' : '',
      finalSubmissionCompleted ? 'final-submission' : ''
    ].filter((section) => section);
    setCompletedSections(newCompletedSections);
  };

  const getLocalInspectionByIdAndUpdate = async (inspectionId: string) => {
    const inspectionData = await getLocalInspectionById(inspectionId);

    if (inspectionData) {
      const inspectionInfo: Partial<Inspection> = {
        id: inspectionId,
        createdBy: inspectionData.createdByEmail,
        createdAt: inspectionData.createdAt,
        updatedBy: inspectionData.updatedByEmail,
        updatedAt: inspectionData.updatedAt,
        inspectionReport: inspectionData.inspectionReport,
        createdByName: inspectionData.createdByName,
        createdByEmail: inspectionData.createdByEmail,
        flogistixId: inspectionData.flogistixId,
        inspectionDate: new Date(inspectionData.inspectionDate),
        status: inspectionData.status?.code as Inspection['status'],
        inspectionType: inspectionData.inspectionType as Inspection['inspectionType'],
        complianceType: inspectionData.complianceType as Inspection['complianceType'],
        state: inspectionData.state,
        county: inspectionData.county,
        latitude: inspectionData.latitude,
        longitude: inspectionData.longitude,
        inspectionFrequency: inspectionData.inspectionFrequency,
        startTime: inspectionData.startTime,
        endTime: inspectionData.endTime,
        contactName: inspectionData.contactName,
        contactPhone: inspectionData.contactPhone,
        contactEmail: inspectionData.contactEmail,
        contactApiNumber: inspectionData.contactApiNumber,
        operationalStatus: inspectionData.operationalStatus as Inspection['operationalStatus'],
        monitoringPlanDeviations: inspectionData.monitoringPlanDeviations,
        locationSignFile: inspectionData.locationSignFile,
        verifications: inspectionData.verifications?.map(
          (verification: IVerification) => new Verification(verification)
        ) || [],
        siteConditions: inspectionData.siteConditions as Inspection['siteConditions'],
        surveys: inspectionData.surveys?.map((survey: ISurvey) => new Survey(survey)) || [],
        leaks: inspectionData.leaks?.map((leak: ILeak) => new Leak(leak)) || [],
        monitoredComponents: inspectionData.monitoredComponents?.map(
          (component: IMonitoredComponent) => new MonitoredComponent(component)
        ) || [],
        files: inspectionData.files,
        org: inspectionData.org,
        site: inspectionData.site
      };
      const newInspection = new Inspection(inspectionInfo as Inspection);
      setInspection(newInspection);
    }
  };

  const fetchInspectionFiles = async (inspectionId: string, authToken: string) => {
    try {
      const files = await fetchFilesForInspection(inspectionId, authToken);
      const options = files.map((file: { id: string, name: string, fileUrl: string, fileType: string }) => ({
        label: file.name,
        value: file.id,
        fileUrl: file.fileUrl,
        fileType: file.fileType
      }));
      setFileOptions(options);
    } catch (error) {
      console.error('Error fetching inspection files:', error);
    }
  };

  const hasFileId = (fileId: string): boolean => {
    if (!inspection) return false;

    let count = 0;

    if (inspection.locationSignFile?.id === fileId) count += 1;

    if (inspection.verifications?.some((verification) => verification.file?.id === fileId)) count += 1;

    inspection.surveys?.forEach((survey) => {
      survey.files?.forEach((file) => {
        if (file.id === fileId) {
          count += 1;
        }
      });
    });

    if (inspection.leaks?.some((leak) => leak.files?.some((file) => file.id === fileId)
      || leak.leakRepairs?.some((repair) => repair.file?.id === fileId))) count += 1;

    if (inspection.files?.some((file) => file.id === fileId)) count += 1;

    return count > 1;
  };

  useEffect(() => {
    const checkHasAllRequiredFields = () => {
      const requiredFieldsCompleted = inspection?.inspectionType
        && inspection?.inspectionDate
        && inspection?.org
        && inspection?.site;

      setHasAllRequiredFields(Boolean(requiredFieldsCompleted));
    };

    checkHasAllRequiredFields();
  }, [
    inspection?.inspectionType,
    inspection?.inspectionDate,
    inspection?.org,
    inspection?.site
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);

        if (inspection?.id && fileOptions.length === 0) {
          await fetchInspectionFiles(inspection.id, accessToken);
        }
      })();
    }
  }, [isAuthenticated, getAccessTokenSilently, inspection?.id, fileOptions.length]);

  useEffect(() => {
    const channel = new BroadcastChannel('transaction-channel');
    channel.onmessage = async (event) => {
      if (event.data.type === 'TRANSACTION_COMPLETED') {
        if (inspection?.id && token) {
          await fetchInspectionFiles(inspection.id, token);
        }
      }
    };
    return () => channel.close();
  }, [inspection?.id, token]);

  useEffect(() => {
    setCompletedSectionsOnChange();
  }, [inspection]);

  const providerValue = useMemo(
    () => ({
      token,
      inspection,
      setInspection,
      completedSections,
      setCompletedSectionsOnChange,
      fileOptions,
      setFileOptions,
      hasFileId,
      getLocalInspectionByIdAndUpdate,
      locationData,
      setLocationData,
      hasAllRequiredFields,
      setHasAllRequiredFields
    }),
    [
      token,
      inspection,
      completedSections,
      fileOptions,
      hasFileId,
      locationData,
      hasAllRequiredFields
    ]
  );

  return (
    <InspectionContext.Provider value={providerValue}>
      {children}
    </InspectionContext.Provider>
  );
};
