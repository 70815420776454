import React, { useState, useContext, useEffect } from 'react';
import { Tooltip } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { IoChevronForward } from 'react-icons/io5';

import { GlobalContext } from '../../../context/GlobalContext';
import {
  addKeys,
  getFilesFromInspection
} from '../../../shared/utils';
import { global } from '../../../shared/colors';
import FilesSkeleton from '../FilesSkeleton/FilesSkeleton';
import Table from '../../../components/Table/Table';
import Status from '../../../components/Status/Status';
import {
  StyledIoDocumentTextOutline,
  StyledIoVideocamOutline,
  StyledIoLocateOutline
} from '../styled-components';
import { Header } from '../../../shared/styled-components';
import {
  StyledTabs,
  IconWrapper,
  HeaderWrapper,
  InspectionID,
  StyledFile,
  StyledDownload,
  StyledTableButtonDiv,
  StyledIoDownloadOutline,
  BatchItems,
  StyledColumnDiv
} from './styled-components';
import { INFINITE } from '../../../shared/constants';
import { urlPrefix } from '../../../shared/url';

import './FilteredFilesTable.scss';

const FilteredFilesTable = ({
  user_organization,
  user_site,
  onOrgBreadcrumbClick,
  onInspectionBreadcrumbClick,
  flogistix_id,
  inspId,
  OnOrgLocationBreadcrumbClick
}) => {
  const [typeTab, setTypeTab] = useState('1');
  const [data, setData] = useState([]);
  const [filesLoading, setFilesLoading] = useState(false);
  const [leakFiles, setLeakFiles] = useState([]);
  const {
    inspectionsReloadIsPending,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    is_admin,
    selectInspection,
    selectedRowKeys,
    setSelectedRowKeys,
    isStep2FormActive,
    triggerGlobalNotification,
    token
  } = useContext(GlobalContext);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const fetchFiles = async () => {
    setFilesLoading(true);
    try {
      const response = await fetch(`https://${urlPrefix}files.api.axil.ai/${inspId}/files`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const files = await response.json();
      setData(files);
    } catch (error) {
      setData([]);
      triggerGlobalNotification(
        'error',
        // eslint-disable-next-line max-len
        'There was a problem loading your files. Try refreshing the page. If the problem persists, please contact support for help.',
        'Failure to load files',
        INFINITE
      );
    }
    setFilesLoading(false);
  };

  const fetchFileById = async (fileId, display = false) => {
    try {
      const response = await fetch(
        `https://${urlPrefix}files.api.axil.ai/files/${fileId}/presigned-url/inline/${display}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      const file = await response.json();
      return file;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const inspectionFiles = getFilesFromInspection(selectInspection);

  const leakAssociations = ['leak', 'leakRepair'];

  const videos = data && addKeys(data?.filter((file) => {
    const foundFile = inspectionFiles.find((f) => f.id === file.id);
    return file.fileType === 'video' && !leakAssociations.includes(foundFile?.association);
  }));
  const leaks = leakFiles && addKeys(leakFiles);
  const reports = data && addKeys(data?.filter((file) => {
    const foundFile = inspectionFiles.find((f) => f.id === file.id);
    if (file.fileType === 'video') return false;
    if (leakAssociations.includes(foundFile?.association)) return false;
    return true;
  }));

  const refreshFiles = async () => {
    if (!inspId || !token) {
      return;
    }
    await fetchFiles();
    setSelectedRowKeys([]);
  };

  const viewFile = async (fileId) => {
    try {
      const file = await fetchFileById(fileId, true);
      window.open(file.url, '_blank');
      return file;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const handleMultiDownload = async (e, singleFile = null) => {
    e.stopPropagation();
    e.preventDefault();
    if (singleFile) {
      try {
        const result = await fetchFileById(singleFile);
        window.open(result.url, '_blank');
      } catch (error) {
        console.error(error);
      }
    } else {
      selectedRowKeys.map(async (fileId) => {
        try {
          const result = await fetchFileById(fileId);
          window.open(result.url, '_blank');
        } catch (error) {
          console.error(error);
        }
      });
    }
  };

  useEffect(() => {
    const leakFileMap = {};
    if (selectInspection && Array.isArray(selectInspection.leaks)) {
      selectInspection.leaks.forEach((leak) => {
        if (leak.files && Array.isArray(leak.files)) {
          leak.files.forEach((file) => {
            leakFileMap[file.id] = leak.tagNumber || '';
          });
        }
      });
    }
    const leakFileIds = Object.keys(leakFileMap);
    if (leakFileIds.length > 0) {
      const filteredLeakFiles = data
        .filter((file) => leakFileIds.includes(file.id))
        .map((file) => ({
          ...file,
          tagNumber: leakFileMap[file.id]
        }));
      setLeakFiles(filteredLeakFiles);
    } else {
      setLeakFiles([]);
    }
  }, [data, selectInspection]);

  useEffect(() => {
    if (inspId) {
      fetchFiles();
    }
    if (inspectionsReloadIsPending) {
      refreshFiles();
    }
  }, [isAuthenticated, getAccessTokenSilently, inspectionsReloadIsPending, inspId]);

  const clientColumns = [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '100%',
      ellipsis: true,
      sortable: true,
      render: (_, record) => (
        <StyledFile>
          <button
            target="_blank"
            aria-label="View File"
            type="submit"
            onClick={() => viewFile(record.id)}
          >
            {record.name}
          </button>
        </StyledFile>
      )
    }
  ];

  const clientColumnsWithTagId = [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '70%',
      ellipsis: true,
      sortable: true,
      render: (_, record) => (
        <StyledFile>
          <button
            target="_blank"
            aria-label="View File"
            type="submit"
            onClick={() => viewFile(record.id)}
          >
            {record.name}
          </button>
        </StyledFile>
      )
    },
    {
      title: 'Tag Id',
      dataIndex: 'tagNumber',
      key: 'tagNumber',
      width: '30%',
      ellipsis: true,
      sortable: true,
      render: (value, record) => (
        <StyledColumnDiv>
          {value || 'No listed tag id'}
          <div className="icon-group">
            <Tooltip
              title="Download Single File"
              color={global.White}
            >
              <StyledDownload
                data-cy="Download"
                onClick={(e) => handleMultiDownload(e, record.id)}
              >
                <StyledIoDownloadOutline className="gray-icon" />
              </StyledDownload>
            </Tooltip>
          </div>
        </StyledColumnDiv>
      )
    }
  ];

  const adminColumns = [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '100%',
      ellipsis: true,
      sortable: true,
      render: (displayValue, record) => (
        <StyledColumnDiv>
          <StyledFile>
            <button
              target="_blank"
              aria-label="View File"
              type="submit"
              onClick={() => viewFile(record.id)}
            >
              {displayValue}
            </button>
          </StyledFile>
          <div className="icon-group">
            <Tooltip
              title="Download Single File"
              color={global.White}
            >
              <StyledDownload
                data-cy="Download"
                onClick={(e) => handleMultiDownload(e, record.id)}
              >
                <StyledIoDownloadOutline className="gray-icon" />
              </StyledDownload>
            </Tooltip>
          </div>
        </StyledColumnDiv>
      )
    }
  ];

  const adminColumnsWithTagId = [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '70%',
      ellipsis: true,
      sortable: true,
      render: (value, record) => (
        <StyledFile>
          <button
            target="_blank"
            aria-label="View File"
            type="submit"
            onClick={() => viewFile(record.id)}
          >
            {value}
          </button>
        </StyledFile>
      )
    },
    {
      title: 'Tag Id',
      dataIndex: 'tagNumber',
      key: 'tagNumber',
      width: '30%',
      ellipsis: true,
      sortable: true,
      render: (value, record) => (
        <IconWrapper>
          {record?.tagNumber || 'no tag id on record'}
          <div className="icon-group">
            <Tooltip
              title="Download Single File"
              color={global.White}
            >
              <StyledDownload
                data-cy="Download"
                onClick={(e) => handleMultiDownload(e, record.id)}
              >
                <StyledIoDownloadOutline className="gray-icon" />
              </StyledDownload>
            </Tooltip>
          </div>
        </IconWrapper>
      )
    }
  ];

  const videoColumn = [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '100%',
      ellipsis: true,
      sortable: true,
      render: (value, record) => (
        <IconWrapper>
          <StyledFile>
            <button
              target="_blank"
              aria-label="View File"
              type="submit"
              onClick={() => viewFile(record.id)}
            >
              {value}
            </button>
          </StyledFile>
          {is_admin && (
            <div className="icon-group">
              <Tooltip
                title="Download Single File"
                color={global.White}
              >
                <StyledDownload
                  data-cy="Download"
                  onClick={(e) => handleMultiDownload(e, record.id)}
                >
                  <StyledIoDownloadOutline className="gray-icon" />
                </StyledDownload>
              </Tooltip>
            </div>
          )}
        </IconWrapper>
      )
    }
  ];

  const items = [
    {
      key: '1',
      label: (
        <>
          <StyledIoDocumentTextOutline />
          <div>Reports</div>
        </>
      ),
      children: (() => {
        if (filesLoading) {
          return <FilesSkeleton justTable />;
        }
        return (
          <>
            {(reports?.length > 0 && (
              <StyledTableButtonDiv>
                <Tooltip
                  title="Download Selected Files"
                  color={global.White}
                >
                  <StyledDownload
                    data-cy="Download"
                    onClick={handleMultiDownload}
                  >
                    <StyledIoDownloadOutline className="gray-icon" />
                  </StyledDownload>
                </Tooltip>
              </StyledTableButtonDiv>
            ))}
            <Table
              columns={is_admin ? adminColumns : clientColumns}
              data={addKeys(reports) || ''}
              onRowSelectionChange={setSelectedRowKeys}
              checkbox
              isUpload
              searchPlaceholder="Filter by display name"
              searchable
            />
          </>
        );
      })()
    },
    {
      key: '2',
      label: (
        <>
          <StyledIoVideocamOutline />
          <div>Videos</div>
        </>
      ),
      children: (() => {
        if (filesLoading) {
          return <FilesSkeleton tableLocation="videos" justTable />;
        }
        return (
          <>
            {(videos?.length > 0 && (
              <BatchItems>
                <Tooltip
                  title="Download Selected Files"
                  color={global.White}
                >
                  <StyledDownload
                    onClick={handleMultiDownload}
                  >
                    <StyledIoDownloadOutline />
                  </StyledDownload>
                </Tooltip>
              </BatchItems>
            ))}
            <Table
              columns={videoColumn}
              data={addKeys(videos) || ''}
              onRowSelectionChange={setSelectedRowKeys}
              checkbox
              isUpload
              searchable
            />
          </>
        );
      })()
    },
    ...(isStep2FormActive
      ? [
        {
          key: '3',
          label: (
            <>
              <StyledIoLocateOutline />
              <div>Leaks</div>
            </>
          ),
          children: (() => {
            if (filesLoading && typeTab === '3') {
              return <FilesSkeleton tableLocation="leaks" justTable />;
            }
            return leaks?.length > 0 ? (
              <>
                <BatchItems>
                  <Tooltip title="Download Selected Files" color={global.White}>
                    <StyledDownload onClick={handleMultiDownload}>
                      <StyledIoDownloadOutline />
                    </StyledDownload>
                  </Tooltip>
                </BatchItems>
                <Table
                  columns={is_admin ? adminColumnsWithTagId : clientColumnsWithTagId}
                  data={addKeys(leaks) || ''}
                  onRowSelectionChange={setSelectedRowKeys}
                  checkbox
                  isUpload
                  searchable
                />
              </>
            ) : (
              <div className="files-table--leak--no-files">No leak files available.</div>
            );
          })()
        }

      ]
      : [])
  ];

  useEffect(() => {
    if (inspId) {
      fetchFiles();
    }
    if (inspectionsReloadIsPending) {
      refreshFiles();
    }
  }, [isAuthenticated, getAccessTokenSilently, inspectionsReloadIsPending]);

  const handleTabChange = (tabKey) => {
    setTypeTab(tabKey);
  };

  return (
    <div className="filtered-files-table">
      <HeaderWrapper>
        <div>
          <Header>{flogistix_id}</Header>
          <Status />
        </div>
        <div className="breadcrumbs">
          <button
            type="button"
            className="inspection-breadcrumb"
            id="InspectionsBreadcrumbFiles"
            onClick={onInspectionBreadcrumbClick}
            aria-label="Inspections"
          >
            Inspections
          </button>
          <IoChevronForward className="breadcrumb-chevron" />
          <button
            type="button"
            className="breadcrumb"
            id="OrgBreadcrumbFiles"
            onClick={onOrgBreadcrumbClick}
            aria-label="Orgs"
          >
            {user_organization.orgName}
          </button>
          <IoChevronForward className="breadcrumb-chevron" />
          <button
            type="button"
            className="breadcrumb"
            id="SiteBreadcrumbFiles"
            onClick={OnOrgLocationBreadcrumbClick}
            aria-label="Sites"
          >
            {user_site.siteName}
          </button>
          <IoChevronForward className="breadcrumb-chevron" />
          <InspectionID id="FlogistixId">
            {flogistix_id}
          </InspectionID>
        </div>
      </HeaderWrapper>
      <StyledTabs
        className="filter-table-tabs"
        activeKey={typeTab}
        onChange={handleTabChange}
        items={items}
      />
    </div>
  );
};

export default FilteredFilesTable;
