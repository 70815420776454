import { Button, Modal } from '@flogistix/flo-ui';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InspectionContext } from '../../../context/InspectionContext';

import './RemainingFields.scss';

const RemainingFieldsModal = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { inspection } = useContext(InspectionContext);

  const remainingFields = inspection?.getRemainingFields() || {
    site: {
      partOne: [],
      partTwo: []
    },
    inspection: {
      info: [],
      weather: [],
      survey: [],
      verification: {
        dailyVerification: [],
        facilitySign: ''
      }
    },
    leak: {
      leak: [],
      repair: []
    }
  };

  const isInspectionVerificationInvalid = () => remainingFields.inspection.verification.dailyVerification.some(
    (dailyVerification) => dailyVerification.length > 0
  )
    || remainingFields.inspection.verification.facilitySign !== '';

  const handleSectionClick = (sectionURL: string) => {
    navigate(sectionURL);
    setShowModal(false);
  };

  const siteDetailsRemainingFields = inspection?.getSiteDetailsRemainingAmount() || 0;
  const inspectionDetailsRemainingFields = inspection?.getInspectionDetailsRemainingAmount() || 0;
  const leakDetailsRemainingFields = inspection?.getLeakDetailsRemainingAmount() || 0;
  const totalRemainingFields = inspection?.getTotalRemainingAmount() || 0;

  const rootPath = `/inspections/${inspection?.id}`;

  return (
    <div className="rf-modal-wrapper">
      <Button
        className="rf-button"
        variation="ghost"
        onClick={() => setShowModal(true)}
        disabled={totalRemainingFields === 0}
      >
        {`${totalRemainingFields} Remaining fields to complete`}
      </Button>
      <Modal
        variant="info"
        className="rf-modal"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title={`${totalRemainingFields} remaining fields`}
        subtitle={`You have ${totalRemainingFields} fields left to complete the form.`}
      >
        <div className="rf-modal--body">
          {siteDetailsRemainingFields !== 0 && (
            <div className="rf-modal--body--section">
              <div className="rf-modal--body--section--title">
                {`Site details • ${siteDetailsRemainingFields} fields`}
              </div>
              {remainingFields.site.partOne.length > 0
            && (
              <div
                className="rf-modal--body--sub-section"
                role="button"
                onClick={() => handleSectionClick(`${rootPath}/site-details/1`)}
                onKeyDown={(e) => e.key === 'Enter'
                && handleSectionClick(`${rootPath}/site-details/1`)}
                tabIndex={0}
              >
                <div className="rf-modal--body--sub-section--title">Part 1 of 2</div>
                {remainingFields.site.partOne.map((field) => (
                  <div className="rf-modal--body--sub-section--bullet">
                    {field}
                  </div>
                ))}
              </div>
            )}
              {remainingFields.site.partTwo.length > 0
            && (
              <div
                className="rf-modal--body--sub-section"
                role="button"
                onClick={() => handleSectionClick(`${rootPath}/site-details/2`)}
                onKeyDown={(e) => e.key === 'Enter'
                && handleSectionClick(`${rootPath}/site-details/2`)}
                tabIndex={0}
              >
                <div className="rf-modal--body--sub-section--title">Part 2 of 2</div>
                {remainingFields.site.partTwo.map((field) => (
                  <div className="rf-modal--body--sub-section--bullet">
                    {field}
                  </div>
                ))}
              </div>
            )}
            </div>
          )}
          {inspectionDetailsRemainingFields !== 0 && (
            <div className="rf-modal--body--section">
              <div className="rf-modal--body--section--title">
                {`Inspection details • ${inspectionDetailsRemainingFields} fields`}
              </div>
              {remainingFields.inspection.info.length > 0 && (
                <div
                  className="rf-modal--body--sub-section"
                  role="button"
                  onClick={() => handleSectionClick(`${rootPath}/inspection-details/1`)}
                  onKeyDown={(e) => e.key === 'Enter'
                && handleSectionClick(`${rootPath}/inspection-details/1`)}
                  tabIndex={0}
                >
                  <div className="rf-modal--body--sub-section--title">Information</div>
                  {remainingFields.inspection.info.map((field) => (
                    <div className="rf-modal--body--sub-section--bullet">
                      {field}
                    </div>
                  ))}
                </div>
              )}
              {isInspectionVerificationInvalid() && (
                <div
                  className="rf-modal--body--sub-section"
                  role="button"
                  onClick={() => handleSectionClick(`${rootPath}/inspection-details/2`)}
                  onKeyDown={(e) => e.key === 'Enter'
                    && handleSectionClick(`${rootPath}/inspection-details/2`)}
                  tabIndex={0}
                >
                  <div className="rf-modal--body--sub-section--title">Verification</div>
                  {remainingFields.inspection.verification.dailyVerification.map((verification, index) => {
                    if (verification.length > 0) {
                      return (
                        <div className="rf-modal--body--sub-section--bullet">
                          {`Verification ${index + 1}`}
                          {verification.map((remainingField) => (
                            <div className="rf-modal--body--sub-section--bullet">
                              {remainingField}
                            </div>
                          ))}
                        </div>
                      );
                    }
                    return null;
                  })}
                  {remainingFields.inspection.verification.facilitySign
                  && (
                    <div className="rf-modal--body--sub-section--bullet">
                      {remainingFields.inspection.verification.facilitySign}
                    </div>
                  )}
                </div>
              )}
              {remainingFields.inspection.weather.length > 0 && (
                <div
                  className="rf-modal--body--sub-section"
                  role="button"
                  onClick={() => handleSectionClick(`${rootPath}/inspection-details/3`)}
                  onKeyDown={(e) => e.key === 'Enter'
                && handleSectionClick(`${rootPath}/inspection-details/3`)}
                  tabIndex={0}
                >
                  <div className="rf-modal--body--sub-section--title">Weather</div>
                  {remainingFields.inspection.weather.map((field) => (
                    <div className="rf-modal--body--sub-section--bullet">
                      {field}
                    </div>
                  ))}
                </div>
              )}
              {remainingFields.inspection.survey.some((survey) => survey.length > 0) && (
                <div
                  className="rf-modal--body--sub-section"
                  role="button"
                  onClick={() => handleSectionClick(`${rootPath}/inspection-details/4`)}
                  onKeyDown={(e) => e.key === 'Enter'
                && handleSectionClick(`${rootPath}/inspection-details/4`)}
                  tabIndex={0}
                >
                  <div className="rf-modal--body--sub-section--title">Surveys</div>
                  {remainingFields.inspection.survey.map((survey, index) => {
                    if (survey.length > 0) {
                      return (
                        <div className="rf-modal--body--sub-section--bullet">
                          {`Survey ${index + 1}`}
                          {survey.map((field) => (
                            <div className="rf-modal--body--sub-section--bullet">
                              {field}
                            </div>
                          ))}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
            </div>
          )}
          {leakDetailsRemainingFields !== 0 && (
            <div className="rf-modal--body--section">
              <div className="rf-modal--body--section--title">
                {`Leak details • ${leakDetailsRemainingFields} fields`}
              </div>
              {remainingFields.leak.leak.some((leak) => leak.length > 0) && (
                <div
                  className="rf-modal--body--sub-section"
                  role="button"
                  onClick={() => handleSectionClick(`${rootPath}/leak-details/1`)}
                  onKeyDown={(e) => e.key === 'Enter'
                && handleSectionClick(`${rootPath}/leak-details/1`)}
                  tabIndex={0}
                >
                  <div className="rf-modal--body--sub-section--title">Part 1 of 2</div>
                  {remainingFields.leak.leak.map((field, index) => {
                    if (field.length > 0) {
                      return (
                        <div className="rf-modal--body--sub-section--bullet">
                          {`Leak ${index + 1}`}
                          {field.map((remainingField) => (
                            <div className="rf-modal--body--sub-section--bullet">
                              {remainingField}
                            </div>
                          ))}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {remainingFields.leak.repair.some((Leak) => Leak.length > 0) && (
                <div
                  className="rf-modal--body--sub-section"
                  role="button"
                  onClick={() => handleSectionClick(`${rootPath}/leak-details/2`)}
                  onKeyDown={(e) => e.key === 'Enter'
                    && handleSectionClick(`${rootPath}/leak-details/2`)}
                  tabIndex={0}
                >
                  <div className="rf-modal--body--sub-section--title">Part 2 of 2</div>
                  {remainingFields.leak.repair.map((field, index) => {
                    if (field.length > 0) {
                      return (
                        <div className="rf-modal--body--sub-section--bullet">
                          {`Repair ${index + 1}`}
                          {field.map((remainingField) => (
                            <div className="rf-modal--body--sub-section--bullet">
                              {remainingField}
                            </div>
                          ))}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default RemainingFieldsModal;
