import React, { useContext, useEffect, useState } from 'react';
import { IoCheckmarkCircle, IoCheckmarkCircleOutline } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Modal } from '@flogistix/flo-ui';

import './InspectionLayout.scss';
import { GlobalContext } from '../../context/GlobalContext';
import { InspectionContext } from '../../context/InspectionContext';
import { deleteInspectionTransactions, deleteLocalInspectionById } from '../../dexie/operations';
import deleteInspection from '../../services/deleteInspectionNew';

type Parts = 'site-details' | 'inspection-details' | 'leak-details' | 'inspection-summary' | 'final-submission';

interface InspectionSideNavProps {
  currentSection?: Parts;
  currentPart?: number;
}

const InspectionSideNav: React.FC<InspectionSideNavProps> = ({ currentSection, currentPart }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const {
    token,
    inspection,
    completedSections,
    hasAllRequiredFields
  } = useContext(InspectionContext);
  const { triggerGlobalNotification, inspections, setInspections } = useContext(GlobalContext);

  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const handleSaveAndExit = () => {
    if (!hasAllRequiredFields) {
      navigate('/');
      return;
    }
    const inspectionExists = inspections.some(
      (i: { inspectionId: string | undefined; }) => i.inspectionId === inspection?.id
    );
    if (!inspectionExists) {
      setInspections(
        [{ ...inspection, inspectionId: inspection?.id, status: { code: inspection?.status?.toLocaleLowerCase() } },
          ...inspections
        ]
      );
    }
    triggerGlobalNotification({
      type: 'success',
      message: 'Inspection has been saved.',
      fixedWidth: '80%'
    });
    navigate('/');
  };

  const renderCheckmark = (section: string) => (completedSections.includes(section) ? (
    <IoCheckmarkCircle className="complete-icon" />
  ) : (
    <IoCheckmarkCircleOutline />
  ));

  const isDisabled = !hasAllRequiredFields;

  const handleDeleteInspection = async () => {
    setDeleteLoading(true);
    await deleteInspectionTransactions(inspection!.flogistixId!);
    if (isOnline) {
      try {
        await deleteInspection(token, inspection!.id!);
        triggerGlobalNotification({
          type: 'success',
          message: 'Inspection has been successfully deleted.',
          fixedWidth: '60%'
        });
      } catch (error) {
        triggerGlobalNotification({
          type: 'error',
          message: `Inspection failed to delete. ${error}`,
          fixedWidth: '60%'
        });
        setDeleteLoading(false);
        setShowModal(false);
        return;
      }
    } else {
      await inspection!.deleteInspection(token);
      triggerGlobalNotification({
        type: 'warning',
        message: 'Unable to delete inspection. It has been scheduled to get deleted once you\'re back online.',
        fixedWidth: '60%'
      });
    }
    await deleteLocalInspectionById(inspection!.id!);
    await setInspections(inspections.filter(
      (i: { inspectionId: string | undefined; }) => i.inspectionId !== inspection?.id
    ));
    setDeleteLoading(false);
    setShowModal(false);
    navigate('/');
  };

  return (
    <nav>
      <div>
        <div className="new-modify-inspection__row">
          <Button
            variation="black-outline"
            fixedWidth="238px"
            onClick={handleSaveAndExit}
            type="button"
          >
            Save and exit
          </Button>
        </div>
        <section className="new-modify-inspection__styled-section">
          <header>Submit your inspection</header>
          <section className="new-modify-inspection__section">
            <div
              className={`new-modify-inspection__item-base
                ${currentSection === 'site-details' ? 'active' : ''}
                ${inspection!.getSiteDetailCompletionStatus() ? 'completed' : ''}
                ${isDisabled ? 'disabled' : ''}`}
              onClick={() => !isDisabled && navigate(`/inspections/${id}/site-details/1`)}
              onKeyDown={
                (e) => e.key === 'Enter' && !isDisabled && navigate(`/inspections/${id}/site-details/1`)
              }
              role="button"
              tabIndex={isDisabled ? -1 : 0}
            >
              <p>Site details</p>
              {renderCheckmark('site-details')}
            </div>
            <div
              className={`new-modify-inspection__item-base ${currentSection === 'inspection-details' ? 'active' : ''}
              ${isDisabled ? 'disabled' : ''}`}
              onClick={() => !isDisabled && navigate(`/inspections/${id}/inspection-details/1`)}
              onKeyDown={
                (e) => e.key === 'Enter' && !isDisabled
                && navigate(`/inspections/${id}/inspection-details/1`)
              }
              role="button"
              tabIndex={isDisabled ? -1 : 0}
            >
              <p>Inspection details</p>
              {renderCheckmark('inspection-details')}
            </div>
            {currentSection === 'inspection-details' && (
              <div className="new-modify-inspection__sub-section">
                <div
                  className={`new-modify-inspection__sub-item ${currentPart === 1 ? 'active' : ''}`}
                  onClick={() => navigate(`/inspections/${id}/${currentSection}/1`)}
                  onKeyDown={(e) => e.key === 'Enter' && navigate(`/inspections/${id}/${currentSection}/1`)}
                  role="button"
                  tabIndex={0}
                >
                  Information
                </div>
                <div
                  className={`new-modify-inspection__sub-item ${currentPart === 2 ? 'active' : ''}`}
                  onClick={() => navigate(`/inspections/${id}/${currentSection}/2`)}
                  onKeyDown={(e) => e.key === 'Enter' && navigate(`/inspections/${id}/${currentSection}/2`)}
                  role="button"
                  tabIndex={0}
                >
                  Verification
                </div>
                <div
                  className={`new-modify-inspection__sub-item ${currentPart === 3 ? 'active' : ''}`}
                  onClick={() => navigate(`/inspections/${id}/${currentSection}/3`)}
                  onKeyDown={(e) => e.key === 'Enter' && navigate(`/inspections/${id}/${currentSection}/3`)}
                  role="button"
                  tabIndex={0}
                >
                  Weather conditions
                </div>
                <div
                  className={`new-modify-inspection__sub-item ${currentPart === 4 ? 'active' : ''}`}
                  onClick={() => navigate(`/inspections/${id}/${currentSection}/4`)}
                  onKeyDown={(e) => e.key === 'Enter' && navigate(`/inspections/${id}/${currentSection}/4`)}
                  role="button"
                  tabIndex={0}
                >
                  Surveys
                </div>
              </div>
            )}
            <div
              className={`new-modify-inspection__item-base ${currentSection === 'leak-details' ? 'active' : ''}
              ${isDisabled ? 'disabled' : ''}`}
              onClick={() => !isDisabled && navigate(`/inspections/${id}/leak-details/1`)}
              onKeyDown={
                (e) => e.key === 'Enter' && !isDisabled && navigate(`/inspections/${id}/leak-details/1`)
              }
              role="button"
              tabIndex={isDisabled ? -1 : 0}
            >
              <p>Leak details</p>
              {renderCheckmark('leak-details')}
            </div>
            <div
              className={`new-modify-inspection__item-base ${currentSection === 'inspection-summary' ? 'active' : ''}
              ${isDisabled ? 'disabled' : ''}`}
              onClick={() => !isDisabled && navigate(`/inspections/${id}/inspection-summary/1`)}
              onKeyDown={
                (e) => e.key === 'Enter' && !isDisabled
                && navigate(`/inspections/${id}/inspection-summary/1`)
              }
              role="button"
              tabIndex={isDisabled ? -1 : 0}
            >
              <p>Inspection summary</p>
              {renderCheckmark('inspection-summary')}
            </div>
            <div
              className={`new-modify-inspection__item-base ${currentSection === 'final-submission' ? 'active' : ''}
              ${isDisabled ? 'disabled' : ''}`}
              onClick={() => !isDisabled && navigate(`/inspections/${id}/final-submission`)}
              onKeyDown={
                (e) => e.key === 'Enter' && !isDisabled
                && navigate(`/inspections/${id}/final-submission`)
              }
              role="button"
              tabIndex={isDisabled ? -1 : 0}
            >
              <p>Final submission</p>
              {renderCheckmark('final-submission')}
            </div>
          </section>
        </section>
      </div>
      <div className="new-modify-inspection__row">
        <Button
          variation="red-outline"
          onClick={() => setShowModal(true)}
          fixedWidth="238px"
          disabled={!hasAllRequiredFields}
        >
          Delete
        </Button>
        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          variant="delete"
          title="Delete inspection"
          subtitle="Are you sure you want to delete this inspection?"
          actions={{
            onConfirm: () => handleDeleteInspection(),
            onCancel: () => setShowModal(false),
            loading: deleteLoading
          }}
        />
      </div>
    </nav>
  );
};

InspectionSideNav.defaultProps = {
  currentSection: 'site-details',
  currentPart: 1
};

export default InspectionSideNav;
