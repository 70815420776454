import { v4 as uuidv4 } from 'uuid';
import { JsonType, Transaction } from '../dexie/db';
import { createTransaction } from '../dexie/operations';
import { urlPrefix } from '../shared/url';

export interface IContact {
  id?: string;
  name?: string;
  email?: string;
  phone?: string;
  parentId?: string;
  version?: number;
  orgs?: {
    id: string;
    name?: string;
    role?: string;
  }[];
  sites?: {
    id: string;
    name?: string;
    role?: string;
  }[];
}

export default class Contact {
  id?: string | null;

  name?: string;

  email?: string;

  phone?: string;

  parentId?: string;

  version?: number;

  orgs?: {
    id: string;
    name?: string;
    role?: string;
  }[];

  sites?: {
    id: string;
    name?: string;
    role?: string;
  }[];

  constructor({
    id,
    name,
    email,
    phone,
    orgs,
    sites,
    parentId,
    version
  }: IContact) {
    this.id = id ?? uuidv4();
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.orgs = orgs;
    this.sites = sites;
    this.parentId = parentId;
    this.version = version ?? 0;
  }

  isEqual(other: Contact) {
    return this.name === other.name
      && this.email === other.email
      && this.parentId === other.parentId
      && this.phone === other.phone;
  }

  async createContact(token: string) {
    const payload = {} as IContact;
    payload.id = this.id ?? uuidv4();
    if (this.name) payload.name = this.name;
    if (this.email) payload.email = this.email;
    if (this.phone) payload.phone = this.phone;
    if (this.orgs) payload.orgs = this.orgs;
    if (this.sites) payload.sites = this.sites;
    if (this.parentId) payload.parentId = this.parentId;

    const creationTransaction = {
      queueId: 'contact->create',
      request: {
        method: 'POST',
        endpoint: `https://${urlPrefix}contacts.api.axil.ai/contacts`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        contactId: payload.id,
        payload: payload as JsonType,
        version: this.version
      }
    } as Transaction;
    await createTransaction(creationTransaction);
  }

  async updateName(token: string, name: string) {
    const updateTransaction = {
      queueId: 'contact->name',
      request: {
        method: 'PATCH',
        endpoint: `https://${urlPrefix}contacts.api.axil.ai/contacts/${this.id}/name/${name}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        payload: {},
        version: this.version
      }
    } as Transaction;
    await createTransaction(updateTransaction);
    this.name = name;
    this.version = (this.version ?? 0) + 1;
  }

  async updateEmail(token: string, email: string) {
    const updateTransaction = {
      queueId: 'contact->email',
      request: {
        method: 'PATCH',
        contactId: this.id,
        endpoint: `https://${urlPrefix}contacts.api.axil.ai/contacts/${this.id}/email/${email}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        payload: {},
        version: this.version
      }
    } as Transaction;
    await createTransaction(updateTransaction);
    this.email = email;
    this.version = (this.version ?? 0) + 1;
  }

  async updatePhone(token: string, phone: string) {
    const updateTransaction = {
      queueId: 'contact->phone',
      request: {
        method: 'PATCH',
        contactId: this.id,
        endpoint: `https://${urlPrefix}contacts.api.axil.ai/contacts/${this.id}/phone/${phone}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        payload: {},
        version: this.version
      }
    } as Transaction;
    await createTransaction(updateTransaction);
    this.phone = phone;
    this.version = (this.version ?? 0) + 1;
  }

  async nullifyEmail(token: string) {
    const updateTransaction = {
      queueId: 'contact->email',
      request: {
        method: 'PATCH',
        contactId: this.id,
        endpoint: `https://${urlPrefix}contacts.api.axil.ai/contacts/${this.id}/nullifyEmail`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        payload: {},
        version: this.version
      }
    } as Transaction;
    await createTransaction(updateTransaction);
    this.email = undefined;
    this.version = (this.version ?? 0) + 1;
  }

  async nullifyPhone(token: string) {
    const updateTransaction = {
      queueId: 'contact->phone',
      request: {
        method: 'PATCH',
        contactId: this.id,
        endpoint: `https://${urlPrefix}contacts.api.axil.ai/contacts/${this.id}/nullifyPhone`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        payload: {},
        version: this.version
      }
    } as Transaction;
    await createTransaction(updateTransaction);
    this.phone = undefined;
    this.version = (this.version ?? 0) + 1;
  }
}
