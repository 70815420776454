import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Header,
  Tabs,
  Tab
} from '@flogistix/flo-ui';
import CreateNewReport from './CreateNewReport/CreateNewReport';
import ScheduledReports, { ReportData } from './ScheduledReports/ScheduledReports';
import './ReportingHome.scss';

const ReportingHome: React.FC = () => {
  const location = useLocation();
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    const path = location.pathname;
    if (path.includes('/reports/new-report')) {
      setActiveKey('new_report');
    } else if (path.includes('/reports/scheduled-reports')) {
      setActiveKey('scheduled_reports');
    } else {
      setActiveKey('new_report');
    }
  }, [location.pathname]);

  useEffect(() => {
    const path = location.pathname;
    if (path.includes('/reports/new-report')) {
      setActiveKey('new_report');
    } else if (path.includes('/reports/scheduled-reports')) {
      setActiveKey('scheduled_reports');
    } else {
      setActiveKey('new_report');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.state) {
      setActiveKey('edit_report');
    }
  }, [location.state]);

  const handleSelect = (key: string) => {
    if (key === 'scheduled_reports') {
      navigate('/reports/scheduled-reports');
    }
    if (key === 'new_report') {
      navigate('/reports');
    }
  };

  const report: ReportData | undefined = location.state ? location.state : undefined;

  return (
    <>
      <Header className="reporting-page-header" headerSize="h1">Reporting</Header>
      {location.pathname && activeKey && (
        <Tabs
          defaultActiveKey={activeKey}
          color="black"
          headerSize="h4"
          fontStyle="semi-bold"
          className="reporting-tabs"
          activeKey={activeKey}
          onSelect={handleSelect}
        >
          <Tab
            eventKey={location.state ? 'edit_report' : 'new_report'}
            title={location.state ? 'Edit Report' : 'Create New Report'}
          >
            <CreateNewReport reportData={report} />
          </Tab>
          <Tab
            eventKey="scheduled_reports"
            title="Scheduled Reports"
          >
            <div className="report-container">
              <ScheduledReports />
            </div>
          </Tab>
        </Tabs>
      )}
    </>
  );
};

export default ReportingHome;
