import { Inspector, Organization } from '../../../dexie/db';

export const getInitials = (name: string) => {
  const parts = name.split(' ');
  if (parts.length < 2) {
    return 'UNKN';
  }

  const firstInitial = parts[0][0];
  const lastName = parts[parts.length - 1];
  const firstThreeOfLast = lastName.substring(0, 3);

  return `${firstInitial}${firstThreeOfLast}`.toUpperCase();
};

export const generateFlogistixId = (name: string) => {
  const initials = name ? getInitials(name) : 'UNKN';
  const timestamp = Math.floor(Date.now() / 1000);
  const flogistixId = `${initials}_inspection_${timestamp}`;
  return flogistixId;
};

export const formatDate = (date: Date | null) => {
  if (!date) return '';
  const [year, month, day] = new Date(date).toISOString().split('T')[0].split('-');
  return `${year}-${month}-${day}`;
};

export const getOrgById = (orgId: number, orgs: Organization[]) => orgs.find(
  (org: Organization) => org.orgId.toString() === orgId.toString()
) || orgId.toString();

export const getInspectorById = (inspectorId: string, inspectors: Inspector[]): string | undefined => {
  for (let i = 0; i < inspectors.length; i += 1) {
    if (inspectors[i].id?.toString() === inspectorId) {
      return inspectors[i].name;
    }
  }

  return undefined;
};

export const formatDateString = (dateString: string): string => {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };

  return date.toLocaleDateString('en-US', options);
};

export const timestampToYMD = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };

  return date.toLocaleDateString('en-US', options);
};
