import { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Dropdown, Input } from '@flogistix/flo-ui';

import { InspectionContext } from '../../../../context/InspectionContext';
import { urlPrefix } from '../../../../shared/url';
import { Contact as ContactType, DropdownOptionType } from '../../shared/typings';
import Contact, { IContact } from '../../../../classes/contact';

const PartTwo = () => {
  const { inspection, token, setCompletedSectionsOnChange } = useContext(InspectionContext);
  const [contactOptions, setContactOptions] = useState([] as DropdownOptionType[]);
  const [contactList, setContactList] = useState([] as ContactType[]);
  const [contactSearch, setContactSearch] = useState('');
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [inspectionContactName, setContactName] = useState(inspection?.contactName || '');
  const [inspectionContactPhone, setContactPhone] = useState(inspection?.contactPhone || '');
  const [inspectionContactEmail, setContactEmail] = useState(inspection?.contactEmail || '');
  const [inspectionLatitude, setLatitude] = useState(inspection?.latitude);
  const [inspectionLongitude, setLongitude] = useState(inspection?.longitude);
  const [inspectionApiNumber, setApiNumber] = useState(inspection?.contactApiNumber || '');

  const updateContactList = (newContact: Contact) => {
    const newContactList = contactList.map((contact) => {
      if (contact.id === newContact.id) {
        return newContact;
      }
      return contact;
    });
    setContactList(newContactList as ContactType[]);
  };

  const clearContactSelection = () => {
    inspection?.removeContactEmail(token);
    inspection?.removeContactName(token);
    inspection?.removeContactPhone(token);
    setSelectedContact(null);
    setContactName('');
    setContactPhone('');
    setContactEmail('');
    setCompletedSectionsOnChange();
  };

  const handleContactCreation = async (contactInfo: IContact) => {
    const { name } = contactInfo;
    if (!name) return;
    const newContact = new Contact({
      id: uuidv4(),
      name: contactInfo.name,
      phone: contactInfo.phone,
      email: contactInfo.email,
      orgs: [{
        id: `${inspection?.org?.id ?? ''}`
      }],
      sites: [{
        id: `${inspection?.site?.id ?? ''}`
      }],
      parentId: `${inspection?.org?.id ?? ''}`
    });
    const newContactList = [...contactList, newContact];
    setContactList(newContactList as ContactType[]);
    newContact.createContact(token);
    setSelectedContact(newContact);
  };

  const handleContactNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const contactName = e.target.value as string;
    if (!contactName.length) {
      clearContactSelection();
    } else if (inspection?.contactName !== contactName) {
      inspection!.updateContactName(token, contactName);
      if (selectedContact && contactName) {
        selectedContact.updateName(token, contactName);
        const name = contactName;
        updateContactList({ ...selectedContact, name } as Contact);
      } else {
        const name = contactName;
        handleContactCreation({
          id: uuidv4(),
          email: inspectionContactEmail,
          phone: inspectionContactPhone,
          name
        });
      }
    }
    setCompletedSectionsOnChange();
    setContactName(contactName);
  };

  const handleContactPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const contactPhone = e.target.value as string;
    if (!contactPhone.length) {
      inspection!.removeContactPhone(token);
      if (selectedContact) {
        selectedContact.nullifyPhone(token);
        updateContactList({ ...selectedContact, phone: undefined } as Contact);
      }
    } else if (inspection?.contactPhone !== contactPhone) {
      inspection!.updateContactPhone(token, contactPhone);
      if (selectedContact) {
        selectedContact.updatePhone(token, contactPhone);
        updateContactList({ ...selectedContact, phone: contactPhone } as Contact);
      } else {
        const name = e.target.value as string;
        handleContactCreation({
          ...(selectedContact || {}),
          id: uuidv4(),
          phone: contactPhone,
          name,
          email: inspectionContactEmail
        });
      }
    }
    setCompletedSectionsOnChange();
    setContactPhone(contactPhone);
  };

  const handleContactEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const contactEmail = e.target.value as string;
    if (!contactEmail.length) {
      inspection!.removeContactEmail(token);
    } else if (inspection?.contactEmail !== contactEmail) {
      inspection!.updateContactEmail(token, contactEmail);
      if (selectedContact) {
        selectedContact.updateEmail(token, contactEmail);
        updateContactList({ ...selectedContact, email: contactEmail } as Contact);
      } else {
        const name = e.target.value as string;
        handleContactCreation({
          id: uuidv4(),
          email: contactEmail,
          name,
          phone: inspectionContactPhone
        });
      }
    }
    setCompletedSectionsOnChange();
    setContactEmail(contactEmail);
  };

  const handleLatitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const latitude = Number(e.target.value);
    if (Number.isNaN(latitude)) {
      inspection!.removeLatitude(token);
    } else if (inspection?.latitude !== latitude) {
      inspection!.updateLatitude(token, latitude);
    }
    setCompletedSectionsOnChange();
    setLatitude(latitude);
  };

  const handleLongitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const longitude = Number(e.target.value);
    if (Number.isNaN(longitude)) {
      inspection!.removeLongitude(token);
    } else if (inspection?.longitude !== longitude) {
      inspection!.updateLongitude(token, longitude);
    }
    setCompletedSectionsOnChange();
    setLongitude(longitude);
  };

  const handleApiChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const apiNumber = e.target.value as string;
    if (!apiNumber.length) {
      inspection!.removeContactApiNumber(token);
    } else if (inspection?.contactApiNumber !== apiNumber) {
      inspection!.updateContactApiNumber(token, apiNumber);
    }
    setCompletedSectionsOnChange();
    setApiNumber(apiNumber);
  };

  const handleContactSearchChange = (searchTerm: string) => {
    setContactSearch(searchTerm);
    const filteredOptions = contactList.filter((contact) => (
      contact.name?.toLowerCase().includes(searchTerm?.toLowerCase())
    ));
    const options = filteredOptions.map((contact: ContactType) => ({
      value: contact.id,
      label: `${contact.name}`
    }));
    setContactOptions(options);
  };

  const handleContactSelection = ({ value }: DropdownOptionType) => {
    handleContactSearchChange('');
    const selectedContactId = value;
    const newContact = contactList.find((contact) => contact.id === selectedContactId) ?? null;
    if (!newContact) return;
    const newSelectedContact = new Contact(newContact);
    setSelectedContact(newSelectedContact);
    const contactName = `${newContact?.name ?? ''}` || '';
    const contactPhone = newContact?.phone || '';
    const contactEmail = newContact?.email || '';
    if (contactName.length) {
      inspection!.updateContactName(token, contactName);
    }
    if (contactPhone.length) {
      inspection!.updateContactPhone(token, contactPhone);
    }
    if (contactEmail.length) {
      inspection!.updateContactEmail(token, contactEmail);
    }
    setContactName(contactName);
    setContactPhone(contactPhone);
    setContactEmail(contactEmail);
    setCompletedSectionsOnChange();
  };

  useEffect(() => {
    const fetchAndSetOrgContacts = async () => {
      try {
        const orgNetsuiteId = await inspection!.org?.id;
        if (!orgNetsuiteId) return;
        const contactsUrl = `https://${urlPrefix}queries.api.axil.ai/v1/contacts?orgId=${orgNetsuiteId}`;
        const contacts = await fetch(contactsUrl, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const contactsData: ContactType[] = await contacts.json();
        const uniqueContacts = contactsData.filter((value, index, self) => index === self.findIndex((t) => (
          t.id === value.id))) ?? [] as ContactType[];
        const options = uniqueContacts.map((contact: ContactType) => ({
          value: contact.id,
          label: `${contact.name}`
        }));
        const selectedContactId = options.find((option) => option.label === inspectionContactName)?.value;
        const selectedContactOption = uniqueContacts.find((contact) => contact.id === selectedContactId) ?? null;
        const newSelectedContact = selectedContactOption ? new Contact(selectedContactOption) : null;
        setSelectedContact(newSelectedContact);
        setContactOptions(options);
        setContactList(uniqueContacts);
      } catch (error) {
        console.error(error);
      }
    };

    if (inspection?.org?.id) {
      fetchAndSetOrgContacts();
    }
  }, [inspection?.org?.id]);

  useEffect(() => {
    const options = contactList.map((contact: ContactType) => ({
      value: contact.id,
      label: `${contact.name}`
    }));
    setContactOptions(options);
  }, [contactList]);

  return (
    <>
      <h1 className="page-details__header">Site details</h1>
      <h4 className="part">Part 2 of 2</h4>
      {contactList.length > 0 && (
        <>
          <label className="label">Select a contact</label>
          <Dropdown
            className="input__dropdown"
            id="contact-dropdown"
            searchValue={contactSearch}
            searchPlaceholder="Search"
            onSearchChange={handleContactSearchChange}
            placeholder="Select a contact"
            value={contactOptions.find((option) => option.value === selectedContact?.id)?.label ?? ''}
            options={contactOptions}
            onSelect={handleContactSelection}
            fixedWidth="100%"
            onClear={clearContactSelection}
          />
        </>
      )}
      <label className="label">Contact name</label>
      <Input
        id="contact-name"
        type="text"
        defaultValue={inspectionContactName}
        value={inspectionContactName}
        onChange={(e) => setContactName(e.target.value)}
        onBlur={handleContactNameChange}
        className="input__text"
        placeholder="John Smith"
        fixedWidth="100%"
      />
      <label className="label">Contact phone</label>
      <Input
        id="contact-phone"
        type="text"
        defaultValue={inspectionContactPhone}
        disabled={!inspectionContactName}
        value={inspectionContactPhone}
        onChange={(e) => setContactPhone(e.target.value)}
        onBlur={handleContactPhoneChange}
        className="input__text"
        placeholder="(555) 555 - 5555"
        fixedWidth="100%"
      />
      <label className="label">Email (optional)</label>
      <Input
        id="contact-email"
        type="text"
        defaultValue={inspectionContactEmail}
        disabled={!inspectionContactName}
        value={inspectionContactEmail}
        onChange={(e) => setContactEmail(e.target.value)}
        onBlur={handleContactEmailChange}
        className="input__text"
        placeholder="john.smith@gmail.com"
        fixedWidth="100%"
      />
      <label className="label">Latitude</label>
      <Input
        id="latitude"
        type="text"
        defaultValue={inspectionLatitude ?? ''}
        onBlur={handleLatitudeChange}
        className="input__text"
        placeholder="35.5397"
        fixedWidth="100%"
      />
      <label className="label">Longitude</label>
      <Input
        id="longitude"
        type="text"
        defaultValue={inspectionLongitude ?? ''}
        onBlur={handleLongitudeChange}
        className="input__text"
        placeholder="-97.5267"
        fixedWidth="100%"
      />
      <label className="label">API # (optional)</label>
      <Input
        id="api-number"
        type="text"
        defaultValue={inspectionApiNumber}
        onBlur={handleApiChange}
        className="input__text"
        placeholder="123 - 456789"
        fixedWidth="100%"
      />
      <span className="spacer" />
    </>
  );
};

export default PartTwo;
