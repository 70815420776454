// eslint-disable-next-line import/no-cycle
import { SkyCondition, WindDirection } from './enums';

export interface ISiteCondition {
  skyConditions?: SkyCondition;
  ambientTemperature?: number;
  windDirection?: WindDirection;
  maxWindSpeed?: number;
  humidityPercent?: number;
  barometricPressure?: number;
  viewingDistance?: number;
}

export class SiteCondition {
  skyConditions?: SkyCondition;

  ambientTemperature?: number;

  windDirection?: WindDirection;

  maxWindSpeed?: number;

  humidityPercent?: number;

  barometricPressure?: number;

  viewingDistance?: number;

  constructor({
    skyConditions,
    ambientTemperature,
    windDirection,
    maxWindSpeed,
    humidityPercent,
    barometricPressure,
    viewingDistance
  }: ISiteCondition = {}) {
    this.skyConditions = skyConditions;
    this.ambientTemperature = ambientTemperature;
    this.windDirection = windDirection;
    this.maxWindSpeed = maxWindSpeed;
    this.humidityPercent = humidityPercent;
    this.barometricPressure = barometricPressure;
    this.viewingDistance = viewingDistance;
  }

  getRemainingFields() {
    const remainingFields: string[] = [];
    if (!this?.maxWindSpeed) remainingFields.push('Max wind speed');
    if (!this?.windDirection) remainingFields.push('Wind direction');
    if (!this?.ambientTemperature) remainingFields.push('Temperature');
    if (!this?.barometricPressure) remainingFields.push('Barometric pressure');
    if (!this?.humidityPercent) remainingFields.push('Humidity');
    if (!this?.skyConditions) remainingFields.push('Sky condition');
    if (!this?.viewingDistance) remainingFields.push('Viewing distance');
    return remainingFields;
  }
}

export default SiteCondition;
