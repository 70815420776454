/* eslint-disable import/prefer-default-export */

const Gray1 = '#1B1A1A';
const Black = '#000000';
const Gray2 = '#424242';
const Gray3 = '#848383';
const Gray4 = '#BCBCBC';
const Gray5 = '#DBDBDB';
const Gray6 = '#F8F8F8';
const Gray7 = '#e6e6e6';
const Gray8 = '#f8f8f8';
const Gray9 = '#98A2B3';
const Gray10 = '##667085';
const HighlightGrey = '#d6dfe2';
const White = '#FFFFFF';
const ErrorRed = '#FF5353';
const WarningYellow = '#DC6803';
const GreenPop = '#00C278';
const PrimaryBlue = '#0A84FF';
const Green = '#32CD32';
const DraftText = '#DAEDFF';
const ReopenedBackground = '#A48B07';
const ReopenedText = '#FFF7CC';
const ReadyBackground = '';
const ReadyText = '#E5FAF2';
const Transparency = 'rgba(0, 0, 0, 0.88)';

export const global = {
  Gray1,
  Black,
  Gray2,
  Gray3,
  Gray4,
  Gray5,
  Gray6,
  Gray7,
  Gray8,
  Gray9,
  Gray10,
  HighlightGrey,
  White,
  ErrorRed,
  WarningYellow,
  GreenPop,
  PrimaryBlue,
  Green,
  DraftText,
  ReopenedBackground,
  ReopenedText,
  ReadyBackground,
  ReadyText,
  Transparency
};
