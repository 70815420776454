import { v4 as uuidv4 } from 'uuid';
import { ComponentType } from './enums';

export interface IMonitoredComponent {
  monitoredComponentId?: string;
  componentType?: ComponentType;
  difficultToMonitor?: boolean;
  difficultToMonitorDescription?: string;
  unsafeToMonitor?: boolean;
  unsafeToMonitorDescription?: string;
}

export default class MonitoredComponent {
  monitoredComponentId?: string;

  componentType?: ComponentType;

  difficultToMonitor?: boolean;

  difficultToMonitorDescription?: string;

  unsafeToMonitor?: boolean;

  unsafeToMonitorDescription?: string;

  constructor({
    monitoredComponentId,
    componentType,
    difficultToMonitor,
    difficultToMonitorDescription,
    unsafeToMonitor,
    unsafeToMonitorDescription
  }: IMonitoredComponent) {
    this.monitoredComponentId = monitoredComponentId ?? uuidv4();
    this.componentType = componentType ?? undefined;
    this.difficultToMonitor = difficultToMonitor ?? false;
    this.difficultToMonitorDescription = difficultToMonitorDescription ?? '';
    this.unsafeToMonitor = unsafeToMonitor ?? false;
    this.unsafeToMonitorDescription = unsafeToMonitorDescription ?? '';
  }

  isEqual(other: MonitoredComponent | undefined) {
    return this.componentType === other?.componentType
      && this.difficultToMonitor === other?.difficultToMonitor
      && this.difficultToMonitorDescription === other?.difficultToMonitorDescription
      && this.unsafeToMonitor === other?.unsafeToMonitor
      && this.unsafeToMonitorDescription === other?.unsafeToMonitorDescription;
  }
}
