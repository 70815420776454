import styled from 'styled-components';

import { global } from './colors';

export const Header = styled.div`
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 32px;
    color: ${global.Gray1};
    font-weight: 600;
    margin-top: 8px;
    white-space: nowrap;
    width: 100%;
    display: flex;
`;

export const Breadcrumbs = styled.div`
    display: flex;
    width: fit-content;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin: 38px 0 18px 0;
`;

export const StyledBreadcrumbs = styled.div`
    display: flex;
    max-width: 800px;
    justify-content: space-between;
    align-items: center;
    margin-left: -4px;
    font-size: 16px;
`;

export const Breadcrumb = styled.button`
    background: none;
    border: none;
    color: ${global.Gray4};
    font-size: 16px;
    cursor: pointer;
    font-weight: 400;
    white-space: nowrap;
    font-size: 16px;
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;

    &:hover {
        text-decoration: underline;
        color: ${global.Black};
    }
`;

export const ButtonContainer = styled.div`
    width: 96px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    margin-left: 8px;
`;

export const StyledSpan = styled.div`
    margin-left: 7px;
    color: ${global.Black};
`;
