/* eslint-disable @typescript-eslint/naming-convention */
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { notification } from 'antd';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useParams, useNavigate } from 'react-router-dom';
import { IoChevronForward } from 'react-icons/io5';
import { Button } from '@flogistix/flo-ui';

import { RiAddFill } from 'react-icons/ri';
import { addKeys } from '../../shared/utils';
import { global } from '../../shared/colors';
import { GlobalContext } from '../../context/GlobalContext';
import { InspectionContext } from '../../context/InspectionContext';
import Table from '../../components/Table/Table';
import Status from '../../components/Status/Status';
import SkeletonTable from '../../components/Table/SkeletonTable';
import { inspectionStatusMap } from '../../shared/constants';
import {
  Breadcrumbs,
  Breadcrumb,
  Header
} from '../../shared/styled-components';
import { InspectionBreadcrumb } from '../Inspections/styled-components';
import { getAllInspections } from '../../dexie/operations';

const timeZone = 'UTC';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  margin: 0 0 5px 0;
`;

const StyledSection = styled.section`
  display: flex;
  margin-right: 40px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  margin-left: 40px;
  margin-right: 32px;
  margin-bottom: 36px;
  height: 80vh;
`;

const StatusPill = styled.span`
  padding: 2px 8px;
  border-radius: 30px;
  background: ${(props) => props.backgroundColor || 'transparent'};
  color: ${(props) => props.color || global.Transparency};
`;

const NoCrumb = styled.div`
  height: 28px;
  min-height: 28px;
`;

const getStatusColors = (status) => {
  switch (status?.code) {
    case 'draft':
      return [global.DraftText, global.PrimaryBlue];
    case 'reopened':
      return [global.ReopenedText, global.ReopenedBackground];
    case 'ready_to_submit':
      return [global.ReadyText, global.ReadyBackground];
    default:
      return ['transparent', global.Transparency];
  }
};

const renderInspectionDate = (inspectionDate) => {
  const dateAsUTC = utcToZonedTime(new Date(inspectionDate), timeZone);
  return format(dateAsUTC, 'MMM dd, yyyy', { timeZone });
};

const inspectionTableColumns = [
  {
    title: 'Site',
    dataIndex: 'site',
    key: 'siteName',
    width: '30%',
    sortable: true,
    render: (site) => site?.name || 'No Location Available'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '20%',
    sortable: true,
    render: (status) => {
      const [backgroundColor, fontColor] = getStatusColors(status);
      return (
        <StatusPill backgroundColor={backgroundColor} color={fontColor}>
          {inspectionStatusMap[status?.code] ?? status?.code}
        </StatusPill>
      );
    }
  },
  {
    title: 'Inspection Date',
    dataIndex: 'inspectionDate',
    key: 'inspectionDate',
    width: '25%',
    sortable: true,
    render: renderInspectionDate
  }
];

const customerViewTableColumns = [
  {
    title: 'Site',
    dataIndex: 'site',
    key: 'name',
    width: '30%',
    sortable: true,
    render: (site) => site?.name || 'No Location Available'
  },
  {
    title: 'Total Inspections',
    dataIndex: 'inspections',
    key: 'inspections',
    width: '20%',
    sortable: true
  },
  {
    title: 'Recent Inspection',
    dataIndex: 'inspectionDate',
    key: 'inspectionDate',
    width: '25%',
    sortable: true,
    render: renderInspectionDate
  }
];

const completedInspectionColumns = [
  {
    title: 'Customer Name',
    dataIndex: 'org',
    key: 'orgName',
    width: '25%',
    sortable: true,
    render: (org) => org?.name || 'No Name Available'
  },
  {
    title: 'Site',
    dataIndex: 'site',
    key: 'siteName',
    width: '30%',
    sortable: true,
    render: (site) => site?.name || 'No Location Available'
  },
  {
    title: 'Total inspections',
    dataIndex: 'inspections',
    key: 'inspections',
    width: '20%',
    sortable: true
  },
  {
    title: 'Inspection Date',
    dataIndex: 'inspectionDate',
    key: 'inspectionDate',
    width: '25%',
    sortable: true,
    render: renderInspectionDate
  }
];

const inspectionTableWithCustomer = [
  {
    title: 'Customer Name',
    dataIndex: 'org',
    key: 'orgName',
    width: '25%',
    sortable: true,
    render: (org) => org?.name || 'No Name Available'
  },
  ...inspectionTableColumns
];

const Home = () => {
  const params = useParams();
  const navigate = useNavigate();
  const {
    inspectionsLoading,
    user_organizations,
    loading,
    getAndSetInspections,
    is_admin,
    triggerGlobalNotification,
    userIsInternal,
    token
  } = useContext(GlobalContext);
  const { locationData, setInspection, inspection } = useContext(InspectionContext);
  const [inspections, setInspections] = useState([]);
  const [netsuiteOrganization, setNetsuiteOrganization] = useState(null);

  const goToStart = () => {
    navigate('/');
  };

  const handleRows = (record) => {
    const location = locationData?.filter((l) => l.id === record.id)[0];
    if (location && location?.inspections > 0) {
      notification.destroy();
      navigate(`/orgs/${record.org.id}/inspections/${record.site.id}`);
    } else {
      triggerGlobalNotification('error', 'This site does not have any inspections to view');
    }
  };

  const tableProps = is_admin ? { enableTabs: true } : {};

  useEffect(() => {
    if (!loading && !inspectionsLoading) {
      const customerId = parseInt(params.customerId, 10);

      setNetsuiteOrganization(
        user_organizations.find((org) => parseInt(org.netsuiteId, 10) === customerId)
      );
    }
  }, [params, inspections, loading, user_organizations]);

  useEffect(() => {
    if (token && !inspections.length) {
      getAndSetInspections();
    }
    if (inspection) {
      setInspection(null);
    }
  }, [token, inspections.length, getAndSetInspections, inspection, setInspection]);

  useEffect(() => {
    const getLocalInspections = async () => {
      const localInspectionData = await getAllInspections();
      setInspections(localInspectionData);
    };

    if (!inspectionsLoading) getLocalInspections();
  }, [inspectionsLoading]);

  useEffect(() => {
    const channel = new BroadcastChannel('transaction-channel');
    channel.onmessage = async (event) => {
      if (event.data.type === 'TRANSACTION_COMPLETED') {
        try {
          await getAndSetInspections();
        } catch (error) {
          console.error('Error fetching inspections after transaction:', error);
        }
      }
    };

    return () => {
      channel.close();
    };
  }, [inspection?.id, token, getAndSetInspections]);

  if (loading) {
    return (
      <StyledDiv>
        <StyledSection>
          <Header>Inspections</Header>
        </StyledSection>
        <NoCrumb />
        <SkeletonTable isCustomer={!userIsInternal} />
      </StyledDiv>
    );
  }

  return (
    <StyledDiv>
      <StyledSection>
        <div>
          <Header>Inspections</Header>
          <Status />
        </div>
        {is_admin && (
          <ButtonWrapper>
            <Button
              type="text"
              data-cy="CreateInspection"
              onClick={() => {
                navigate('/inspections');
              }}
            >
              <RiAddFill size={16} style={{ marginRight: '4px', verticalAlign: 'text-top' }} />
              Create new inspection
            </Button>
          </ButtonWrapper>
        )}
      </StyledSection>
      {netsuiteOrganization ? (
        <Breadcrumbs>
          <InspectionBreadcrumb
            id="InspectionsBreadcrumbHome"
            onClick={goToStart}
            aria-label="Inspections"
          >
            Inspections
          </InspectionBreadcrumb>
          <IoChevronForward className="breadcrumb-chevron" />
          <Breadcrumb
            id="OrgBreadcrumbHomeInspections"
            aria-label="Inspection"
          >
            {netsuiteOrganization.orgName}
          </Breadcrumb>
        </Breadcrumbs>
      ) : (
        <NoCrumb />
      )}
      <Table
        data={addKeys(locationData) || ''}
        columns={
          !userIsInternal
            ? customerViewTableColumns
            : inspectionTableWithCustomer
        }
        completedColumns={completedInspectionColumns}
        onRow={handleRows}
        {...tableProps}
      />
    </StyledDiv>
  );
};

export default Home;
