import { useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IoAddOutline } from 'react-icons/io5';

import { InspectionContext } from '../../../../../context/InspectionContext';
import Survey from '../../../../../classes/survey';
import SurveyCard from './SurveyCard';

const Surveys = () => {
  const { inspection, token, setCompletedSectionsOnChange } = useContext(InspectionContext);

  const createNewSurvey = () => {
    const newSurvey = new Survey({ surveyId: uuidv4() });
    inspection!.addSurvey(token, newSurvey).then(() => {
      setCompletedSectionsOnChange();
    });
  };

  const deleteSurvey = (survey: Survey) => {
    inspection!.removeSurvey(token, survey).then(() => {
      setCompletedSectionsOnChange();
    });
  };

  useEffect(() => {
    if (!inspection!.surveys || inspection!.surveys.length === 0) {
      createNewSurvey();
    }
  }, []);

  return (
    <div>
      <div>
        <h5 className="page-details__header">Inspection details</h5>
        <h4 className="part">Part 4 of 4: Surveys</h4>
        <button
          type="button"
          onClick={createNewSurvey}
          className="add-new-item-button"
        >
          <IoAddOutline />
          Add new Survey
        </button>
        {
          inspection!.surveys?.map((survey, i) => (
            <SurveyCard
              key={survey.surveyId}
              survey={survey}
              deleteSurvey={() => deleteSurvey(survey)}
              canDelete={i !== 0}
            />
          ))
        }
        <button
          type="button"
          onClick={createNewSurvey}
          className="add-new-item-button-bottom"
        >
          <IoAddOutline />
          Add new Survey
        </button>
      </div>
      <span className="spacer" />
    </div>
  );
};

export default Surveys;
