/* eslint-disable import/no-cycle */
import { v4 as uuidv4 } from 'uuid';

import { Transaction } from '../dexie/db';
import { createTransaction } from '../dexie/operations';
import AirMethaneFile, { UploadFile } from './airmethane-file';
import { API_INSPECTION, urlPrefix } from '../shared/url';
import { Inspection } from './inspection';
// eslint-disable-next-line import/no-named-as-default
import Leak from './leak';

interface ILeakRepair {
  leakRepairId?: string;
  date?: Date;
  repaired?: boolean;
  repairedBy?: string;
  method?: string;
  notes?: string;
  file?: AirMethaneFile;
  pendingFiles?: UploadFile[];
  created?: boolean;
  tagNumber?: string;
}

class LeakRepair {
  leakRepairId?: string;

  created?: boolean;

  tagNumber?: string;

  date?: Date;

  repaired?: boolean;

  repairedBy?: string;

  method?: string;

  notes?: string;

  file?: AirMethaneFile;

  pendingFiles?: UploadFile[];

  constructor({
    leakRepairId,
    date,
    repaired,
    repairedBy,
    method,
    notes,
    file,
    pendingFiles,
    created,
    tagNumber
  }: ILeakRepair) {
    this.leakRepairId = leakRepairId ?? uuidv4();
    this.date = date;
    this.repaired = repaired;
    this.repairedBy = repairedBy;
    this.method = method;
    this.notes = notes;
    this.file = file;
    this.pendingFiles = pendingFiles;
    this.created = created;
    this.tagNumber = tagNumber;
  }

  updateCreated(created: boolean) {
    this.created = created;
  }

  updateTagNumber(tagNumber: string) {
    this.tagNumber = tagNumber;
  }

  async patchLeakRepair(
    token: string,
    flogistixId: string,
    inspection: Inspection,
    leakRepair: LeakRepair
  ) {
    this.date = leakRepair.date;
    this.repaired = leakRepair.repaired;
    this.repairedBy = leakRepair.repairedBy;
    this.method = leakRepair.method;
    this.notes = leakRepair.notes;
    this.pendingFiles = leakRepair.pendingFiles;
    this.tagNumber = leakRepair.tagNumber;

    const {
      pendingFiles,
      tagNumber,
      file,
      ...repairObj
    } = leakRepair;

    if (this.pendingFiles?.length) {
      this.file = new AirMethaneFile(this.pendingFiles[0].id);
    } else if (file) {
      this.file = file;
    }

    const validPendingFiles = pendingFiles?.filter((f) => f && f.file) || [];
    const validUploadedFiles = this.file ? [this.file].filter((f) => f && f.id) : [];

    if (flogistixId) {
      const transaction = {
        queueId: `${flogistixId}->leaks->${tagNumber}->repair`,
        request: {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {
            body: {
              ...repairObj,
              file: this.file ? { id: this.file.id } : null
            },
            pendingFiles: validPendingFiles,
            uploadedFiles: validUploadedFiles,
            inspectionId: inspection.id
          },
          fileUpload: true,
          endpoint: `${API_INSPECTION}/${inspection.id}/leaks/${this.tagNumber}/patchLeakRepair`
        }
      } as Transaction;

      this.pendingFiles = [];
      const updatedInspection = new Inspection(inspection);
      const leakOfRepair = updatedInspection.leaks?.find((leak) => leak.tagNumber === tagNumber);
      const newRepairs = leakOfRepair?.leakRepairs?.map((repair) => {
        if (repair.leakRepairId === this.leakRepairId) {
          return this;
        }
        return repair;
      });
      leakOfRepair!.leakRepairs = newRepairs;
      const newLeaks = updatedInspection.leaks?.map((leak) => {
        if (leak.tagNumber === tagNumber) {
          return leakOfRepair;
        }
        return leak;
      });
      updatedInspection.leaks = newLeaks as Leak[];
      await createTransaction(transaction, updatedInspection);
    }
  }

  async attachExistingFileForLeakRepair(
    token: string,
    flogistixId: string,
    inspection: Inspection,
    leakRepair: LeakRepair
  ) {
    this.date = leakRepair.date;
    this.repaired = leakRepair.repaired;
    this.repairedBy = leakRepair.repairedBy;
    this.method = leakRepair.method;
    this.notes = leakRepair.notes;
    this.file = leakRepair.file;
    this.tagNumber = leakRepair.tagNumber;
    const {
      pendingFiles,
      tagNumber,
      file,
      ...repairObj
    } = leakRepair;
    if (flogistixId) {
      const transaction = {
        queueId: `${flogistixId}->leaks->${tagNumber}->repair`,
        request: {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {
            body: {
              ...repairObj
            },
            pendingFiles: [],
            uploadedFiles: file ? [file] : [],
            inspectionId: inspection!.id!
          },
          fileUpload: true,
          endpoint: `${API_INSPECTION}/${inspection!.id!}/leaks/${this.tagNumber}/patchLeakRepair`
        }
      } as Transaction;
      this.file = this.pendingFiles?.length ? new AirMethaneFile(this.pendingFiles?.at(0)?.id) : leakRepair.file;
      this.pendingFiles = [];
      const updatedInspection = new Inspection(inspection);
      const leakOfRepair = updatedInspection.leaks?.find((leak) => leak.tagNumber === tagNumber);
      const newRepairs = leakOfRepair?.leakRepairs?.map((repair) => {
        if (repair.leakRepairId === this.leakRepairId) {
          return this;
        }
        return repair;
      });
      leakOfRepair!.leakRepairs = newRepairs;
      const newLeaks = updatedInspection.leaks?.map((leak) => {
        if (leak.tagNumber === tagNumber) {
          return leakOfRepair;
        }
        return leak;
      });
      updatedInspection.leaks = newLeaks as Leak[];
      await createTransaction(transaction, updatedInspection);
    }
  }

  async addFile(token: string, flogistixId: string, inspectionId: string, file: AirMethaneFile) {
    this.file = file;
    const transaction = {
      queueId: `${flogistixId}->leaks->${this.tagNumber}->repair->${this.leakRepairId}`,
      request: {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: file as object,
        endpoint: `${API_INSPECTION}/${inspectionId}/leaks/${this.tagNumber}/leakRepairs/${this.leakRepairId}/addFile`
      }
    } as Transaction;
    await createTransaction(transaction);
  }

  async removeFile(
    token: string,
    flogistixId: string,
    file: AirMethaneFile,
    fileVersion: number,
    inspectionId: string,
    inspection: Inspection
  ) {
    if (flogistixId && file?.id) {
      const queueId = `${flogistixId}->leaks->${this.tagNumber}->repair->${this.leakRepairId}`;

      const deleteTransaction = {
        queueId,
        request: {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          endpoint: `https://${urlPrefix}files.api.axil.ai/files/${file?.id}`,
          payload: {},
          version: `${fileVersion}`,
          fileUpload: false
        }
      } as Transaction;

      await createTransaction(deleteTransaction);

      this.file = undefined;

      const updatedInspection = new Inspection(inspection);
      const leakIndex = updatedInspection.leaks?.findIndex((leak) => leak.tagNumber === this.tagNumber);

      if (leakIndex !== -1 && leakIndex !== undefined) {
        const leakOfRepair = updatedInspection.leaks![leakIndex];

        const newRepairs = leakOfRepair.leakRepairs?.map((repair) => {
          if (repair.leakRepairId === this.leakRepairId) {
            return this;
          }
          return repair;
        });

        leakOfRepair.leakRepairs = newRepairs as LeakRepair[];

        updatedInspection.leaks![leakIndex] = leakOfRepair;
      }

      await this.detachFile(token, flogistixId, inspectionId, updatedInspection);
    }
  }

  async detachFile(
    token: string,
    flogistixId: string,
    inspectionId: string,
    inspection: Inspection
  ) {
    if (flogistixId) {
      const queueId = `${flogistixId}->leaks->${this.tagNumber}->repair->${this.leakRepairId}`;

      this.file = undefined;

      const updatedInspection = new Inspection(inspection);
      const leakIndex = updatedInspection.leaks?.findIndex((leak) => leak.tagNumber === this.tagNumber);

      if (leakIndex !== -1 && leakIndex !== undefined) {
        const leakToUpdate = updatedInspection.leaks![leakIndex];

        const newRepairs = leakToUpdate.leakRepairs?.map((repair) => {
          if (repair.leakRepairId === this.leakRepairId) {
            return new LeakRepair({
              ...repair,
              file: undefined
            });
          }
          return repair;
        });

        leakToUpdate.leakRepairs = newRepairs as LeakRepair[];

        updatedInspection.leaks![leakIndex] = leakToUpdate;
      }

      const { pendingFiles, ...leakRepairObject } = this;

      const removalTransaction = {
        queueId,
        inspectionId,
        request: {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {
            body: { ...leakRepairObject, file: null },
            pendingFiles: [],
            uploadedFiles: null,
            inspectionId
          },
          fileUpload: true,
          endpoint: `${API_INSPECTION}/${inspectionId}/leaks/${this.tagNumber}/patchLeakRepair`
        }
      } as unknown as Transaction;

      await createTransaction(removalTransaction, updatedInspection);
    }
  }

  getSubmissionReadiness() {
    return (
      this.date !== undefined
      && this.file?.id !== undefined
    );
  }

  getRepairCompletionPercentage() {
    const requiredFields = 3;
    const existingFields = [
      this.date,
      this.file?.id,
      this.leakRepairId
    ].filter((field) => !!field);
    return (existingFields.length / requiredFields) * 100;
  }

  getRemainingFields() {
    const remainingFields: string[] = [];
    if (!this.file?.id) remainingFields.push('OGI image of repaired');
    if (!this.date) remainingFields.push('Completion Date');
    if (!this.leakRepairId) remainingFields.push('Leak ID/Tag');
    if (!this.repaired === undefined) remainingFields.push('Leak was fully repaired');
    return remainingFields;
  }
}

export default LeakRepair;
