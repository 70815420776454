/* eslint-disable max-len */
import {
  API_URL,
  API_INSPECTORS,
  API_INSTRUMENTS,
  API_INSPECTION,
  urlPrefix,
  API_INSPECTIONS_BY_ORGS
} from '../shared/url';
import { db } from '../dexie/db';

const defaultHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer '
};

const addTokenToHeader = (header, token) => {
  const authorization = header.Authorization + token;
  return {
    ...header,
    Authorization: authorization
  };
};

const makeFilterString = (filters) => {
  if (!filters || filters.length === 0) {
    return '';
  }
  const keys = Object.keys(filters);

  const string = keys.reduce((acc, key, index) => {
    const isLastItem = keys.length - 1 === index;
    return `${acc}${key}=${filters[key]}${isLastItem ? '' : '&'}`;
  }, '?');

  return string;
};

const handleResponse = async (response) => {
  if (!response.ok) {
    const resultJson = await response.json();
    throw new Error(`${resultJson.status}: ${resultJson.message}`);
  } else {
    return response.json();
  }
};

const makeGetRequest = async (endpoint, token, filters = null) => {
  const filterString = makeFilterString(filters);
  const response = await fetch(`${API_URL}/${endpoint}${filterString}`, {
    method: 'GET',
    headers: addTokenToHeader(defaultHeaders, token)
  });

  return handleResponse(response);
};

const makePostRequest = async (endpoint, token, payload = null) => {
  const response = await fetch(`${API_URL}/${endpoint}`, {
    method: 'POST',
    headers: addTokenToHeader(defaultHeaders, token),
    body: JSON.stringify(payload)
  });

  return handleResponse(response);
};

const makeDeleteRequest = async (endpoint, token, id) => {
  if (!id) {
    throw new Error('Must have an id for delete method.');
  }
  const response = await fetch(`${API_URL}/${endpoint}/id/${id}`, {
    method: 'DELETE',
    headers: addTokenToHeader(defaultHeaders, token)
  });

  return handleResponse(response);
};

const makePutRequest = async (endpoint, token, payload = null) => {
  const { id } = payload;
  if (!id) {
    throw new Error('Must have an id for PUT payload.');
  }
  const response = await fetch(`${API_URL}/${endpoint}/id/${id}`, {
    method: 'PUT',
    headers: addTokenToHeader(defaultHeaders, token),
    body: JSON.stringify(payload)
  });

  return handleResponse(response);
};

const makePatchRequest = async (endpoint, token, payload = null) => {
  const { id } = payload;
  if (!id) {
    throw new Error('Must have an id for PATCH payload.');
  }
  const response = await fetch(`${API_URL}/${endpoint}/id/${id}`, {
    method: 'PATCH',
    headers: addTokenToHeader(defaultHeaders, token),
    body: JSON.stringify(payload)
  });

  return handleResponse(response);
};

// eslint-disable-next-line consistent-return
export const getNewInspectors = async (token) => {
  try {
    if (!token) {
      throw new Error('Token is required for fetching inspectors.');
    }
    const response = await fetch(API_INSPECTORS, {
      method: 'GET',
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`API responded with status: ${response.status}`);
    }

    const inspectors = await response.json();

    return inspectors;
  } catch (error) {
    console.error('Fetch error for New Inspectors:', error);

    try {
      const cachedInspectors = await db.inspectors.toArray();
      if (cachedInspectors.length === 0) {
        throw new Error('No inspectors found in cache.');
      }
      return cachedInspectors;
    } catch (dbError) {
      console.error('Error retrieving inspectors from IndexedDB:', dbError);
      throw dbError;
    }
  }
};

export const getContactVersionById = async (contactId, token) => {
  try {
    const response = await fetch(`https://${urlPrefix}contacts.api.axil.ai/contacts/${contactId}`, {
      method: 'GET',
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`Error fetching contact: ${response.statusText}`);
    }

    const responseData = await response.json();

    const { version } = responseData;

    if (!version) {
      throw new Error('Version not found in the response body');
    }

    return version;
  } catch (error) {
    console.error('Error fetching contact version:', error);
    throw error;
  }
};

export const getInspectionVersionById = async (inspectionId, token) => {
  try {
    const response = await fetch(`https://${urlPrefix}inspections.api.axil.ai/inspections/${inspectionId}`, {
      method: 'GET',
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`Error fetching inspection: ${response.statusText}`);
    }

    const responseData = await response.json();

    const { version } = responseData;

    if (!version) {
      throw new Error('Version not found in the response body');
    }

    return version;
  } catch (error) {
    console.error('Error fetching inspection version:', error);
    throw error;
  }
};

// eslint-disable-next-line consistent-return
export const getNewInstruments = async (token) => {
  try {
    if (!token) {
      throw new Error('Token is required for fetching Instruments.');
    }
    const response = await fetch(API_INSTRUMENTS, {
      method: 'GET',
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`API responded with status: ${response.status}`);
    }

    const instruments = await response.json();

    return instruments;
  } catch (error) {
    console.error('Fetch error for New Instruments:', error);

    try {
      const cachedInstruments = await db.instruments.toArray();
      if (cachedInstruments.length === 0) {
        throw new Error('No Instruments found in cache.');
      }
      return cachedInstruments;
    } catch (dbError) {
      console.error('Error retrieving Instruments from IndexedDB:', dbError);
      throw dbError;
    }
  }
};

export const getNewInspections = async (token) => {
  try {
    const response = await fetch(API_INSPECTIONS_BY_ORGS, {
      method: 'GET',
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`
      }
    });
    return await handleResponse(response);
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
};

export const getNewInspection = async (token, id) => {
  try {
    const response = await fetch(`${API_INSPECTION}/${id}`, {
      method: 'GET',
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`
      }
    });
    return await handleResponse(response);
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
};

export const getSitesAndOrgs = async (token) => {
  try {
    if (!token) {
      throw new Error('Token is required for fetching sites by org.');
    }

    const response = await fetch(`https://${urlPrefix}api.axil.ai/iam/sites-by-org`, {
      method: 'GET',
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`API responded with status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Fetch error for Sites by Org:', error);

    try {
      const cachedOrgs = await db.orgs.toArray();
      if (cachedOrgs.length === 0) {
        throw new Error('No orgs found in cache.');
      }
      return cachedOrgs;
    } catch (dbError) {
      console.error('Error retrieving orgs from IndexedDB:', dbError);
      throw dbError;
    }
  }
};

export const fetchFileData = async (fileId, token) => {
  try {
    const response = await fetch(`https://${urlPrefix}files.api.axil.ai/files/${fileId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    }
    throw new Error('Failed to fetch resource');
  } catch (error) {
    console.error('Error fetching file data:', error);
    return null;
  }
};

export const fetchFilesForInspection = async (parentId, token) => {
  try {
    const response = await fetch(`https://${urlPrefix}files.api.axil.ai/${parentId}/files`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    }
    throw new Error('Failed to fetch resource');
  } catch (error) {
    console.error('Error fetching file data:', error);
    return null;
  }
};

export const fetchFileUrl = async (fileId, token) => {
  try {
    const response = await fetch(`https://${urlPrefix}files.api.axil.ai/files/${fileId}/presigned-url/inline/True`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    }
    throw new Error('Failed to fetch resource');
  } catch (error) {
    console.error('Error fetching file data:', error);
    throw error;
  }
};

export const getContacts = async (token, filters = null) => makeGetRequest('contacts', token, filters);

export const getCredentials = async (token, filters = null) => makeGetRequest('credentials', token, filters);

export const getDronePilots = async (token, filters = null) => makeGetRequest('drone-pilots', token, filters);

export const getEnumerations = async (token, filters = null) => makeGetRequest('enumerations', token, filters);

export const getFiles = async (token, filters = null) => makeGetRequest('files', token, filters);

export const getInspections = async (token, filters = null) => makeGetRequest('inspections', token, filters);

export const getInspectors = async (token, filters = null) => makeGetRequest('inspectors', token, filters);

export const getInspection = async (token, id, filters = null) => makeGetRequest(`inspections/id/${id}`, token, filters);

export const getLeaks = async (token, filters = null) => makeGetRequest('leaks', token, filters);

export const getLeakDetections = async (token, filters = null) => makeGetRequest('leak-detections', token, filters);

export const getLeakRepairs = async (token, filters = null) => makeGetRequest('leak-repairs', token, filters);

export const getMethods = async (token, filters = null) => makeGetRequest('methods', token, filters);

export const getSiteConditions = async (token, filters = null) => makeGetRequest('site-conditions', token, filters);

export const getSensorTypes = async (token, filters = null) => makeGetRequest('sensor-types', token, filters);

export const getStatuses = async (token, filters = null) => makeGetRequest('statuses', token, filters);

export const getSurveys = async (token, filters = null) => makeGetRequest('surveys', token, filters);

export const getSurveyInstruments = async (token, filters = null) => makeGetRequest('survey-instruments', token, filters);

export const getVerifications = async (token, filters = null) => makeGetRequest('verifications', token, filters);

export const getComponentTypes = async (token, filters = null) => makeGetRequest('enumerations?type=componentType', token, filters);

export const getSkyConditions = async (token, filters = null) => makeGetRequest('enumerations?type=skyConditions', token, filters);

export const getInspectionFrequency = async (token, filters = null) => makeGetRequest('enumerations?type=inspectionFrequency', token, filters);

export const getWindDirections = async (token, filters = null) => makeGetRequest('enumerations?type=windDirection', token, filters);

export const postContact = async (token, payload = null) => makePostRequest('contacts', token, payload);

export const postCredential = async (token, payload = null) => makePostRequest('credentials', token, payload);

export const postFile = async (token, payload = null) => makePostRequest('files', token, payload);

export const postInspection = async (token, payload = null) => makePostRequest('inspections', token, payload);

export const postInspector = async (token, payload = null) => makePostRequest('inspectors', token, payload);

export const postLeak = async (token, payload = null) => makePostRequest('leaks', token, payload);

export const postLeakDetection = async (token, payload = null) => makePostRequest('leak-detections', token, payload);

export const postLeakRepair = async (token, payload = null) => makePostRequest('leak-repairs', token, payload);

export const postMethod = async (token, payload = null) => makePostRequest('methods', token, payload);

export const postMonitoredComponent = async (token, payload = null) => makePostRequest('monitored-components', token, payload);

export const postSiteCondition = async (token, payload = null) => makePostRequest('site-conditions', token, payload);

export const postSensorType = async (token, payload = null) => makePostRequest('sensor-types', token, payload);

export const postStatus = async (token, payload = null) => makePostRequest('statuses', token, payload);

export const postSurvey = async (token, payload = null) => makePostRequest('surveys', token, payload);

export const postSurveyInspector = async (token, payload = null) => makePostRequest('survey-inspectors', token, payload);

export const postSurveyInstrument = async (token, payload = null) => makePostRequest('survey-instruments', token, payload);

export const postVerification = async (token, payload = null) => makePostRequest('verifications', token, payload);

export const putInspection = async (token, payload = null) => makePutRequest('inspections', token, payload);

export const patchContact = async (token, payload = null) => makePatchRequest('contacts', token, payload);

export const patchCredential = async (token, payload = null) => makePatchRequest('credentials', token, payload);

export const patchFile = async (token, payload = null) => makePatchRequest('files', token, payload);

export const patchInspector = async (token, payload = null) => makePatchRequest('inspectors', token, payload);

export const patchLeak = async (token, payload = null) => makePatchRequest('leaks', token, payload);

export const patchLeakDetection = async (token, payload = null) => makePatchRequest('leak-detections', token, payload);

export const patchLeakRepair = async (token, payload = null) => makePatchRequest('leak-repairs', token, payload);

export const patchMethod = async (token, payload = null) => makePatchRequest('methods', token, payload);

export const patchMonitoredComponent = async (token, payload = null) => makePatchRequest('monitored-components', token, payload);

export const patchSensorType = async (token, payload = null) => makePatchRequest('sensor-types', token, payload);

export const patchSiteCondition = async (token, payload = null) => makePatchRequest('site-conditions', token, payload);

export const patchStatus = async (token, payload = null) => makePatchRequest('statuses', token, payload);

export const patchSurvey = async (token, payload = null) => makePatchRequest('surveys', token, payload);

export const patchSurveyInstrument = async (token, payload = null) => makePatchRequest('survey-instruments', token, payload);

export const patchVerification = async (token, payload = null) => makePatchRequest('verifications', token, payload);

export const deleteContact = async (token, id) => makeDeleteRequest('contacts', token, id);

export const deleteCredential = async (token, id) => makeDeleteRequest('credentials', token, id);

export const deleteFile = async (token, id) => makeDeleteRequest('files', token, id);

export const deleteInspection = async (token, id) => makeDeleteRequest('inspections', token, id);

export const deleteInspector = async (token, id) => makeDeleteRequest('inspectors', token, id);

export const deleteLeak = async (token, id) => makeDeleteRequest('leaks', token, id);

export const deleteLeakDetection = async (token, id) => makeDeleteRequest('leak-detections', token, id);

export const deleteLeakRepair = async (token, id) => makeDeleteRequest('leak-repairs', token, id);

export const deleteMethod = async (token, id) => makeDeleteRequest('methods', token, id);

export const deleteMonitoredComponent = async (token, id) => makeDeleteRequest('monitored-components', token, id);

export const deleteSiteCondition = async (token, id) => makeDeleteRequest('site-conditions', token, id);

export const deleteSensorType = async (token, id) => makeDeleteRequest('sensor-types', token, id);

export const deleteStatus = async (token, id) => makeDeleteRequest('statuses', token, id);

export const deleteSurvey = async (token, id) => makeDeleteRequest('surveys', token, id);

export const deleteSurveyInspector = async (token, id) => makeDeleteRequest('survey-inspectors', token, id);

export const deleteSurveyInstrument = async (token, id) => makeDeleteRequest('survey-instruments', token, id);

export const deleteVerification = async (token, id) => makeDeleteRequest('verifications', token, id);
