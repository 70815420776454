// eslint-disable-next-line import/no-cycle
import { Transaction } from '../dexie/db';
import { createTransaction } from '../dexie/operations';
import { API_INSPECTION, urlPrefix } from '../shared/url';
import AirMethaneFile, { UploadFile } from './airmethane-file';
import { Inspection } from './inspection';
import SurveyInstrument from './survey-instrument';

export interface IVerification {
  verificationId?: string | null;
  altitude?: number;
  surveyInstrument?: SurveyInstrument;
  file?: AirMethaneFile;
  pendingFiles?: UploadFile[];
}

export default class Verification {
  verificationId?: string | null;

  altitude?: number;

  surveyInstrument?: SurveyInstrument;

  file?: AirMethaneFile;

  pendingFiles?: UploadFile[];

  constructor({
    verificationId,
    altitude,
    surveyInstrument,
    file,
    pendingFiles
  }: IVerification) {
    this.verificationId = verificationId;
    this.altitude = altitude;
    this.surveyInstrument = surveyInstrument;
    this.file = file;
    this.pendingFiles = pendingFiles;
  }

  isEqual(other: Verification) {
    return this.altitude === other.altitude
      && this.surveyInstrument?.isEqual(other.surveyInstrument)
      && this.file?.isEqual(other.file);
  }

  updateFileValue(newFile : AirMethaneFile) {
    this.file = newFile;
  }

  async patchVerification(token: string, flogistixId: string, inspection: Inspection, verification: Verification) {
    this.altitude = verification.altitude;
    this.surveyInstrument = verification.surveyInstrument;
    this.pendingFiles = verification.pendingFiles ?? [];

    const { pendingFiles, ...verificationObject } = verification;
    if (flogistixId) {
      const transaction = {
        queueId: `${flogistixId}->verification->${this.verificationId}`,
        inspectionId: inspection.id,
        request: {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {
            body: {
              ...verificationObject
            },
            pendingFiles,
            uploadedFiles: this.file ? [this.file] : null,
            inspectionId: inspection.id
          },
          fileUpload: true,
          endpoint: `${API_INSPECTION}/${inspection.id}/patchVerification`
        }
      } as Transaction;
      this.pendingFiles = [];
      if (pendingFiles?.length) this.file = new AirMethaneFile(pendingFiles!.at(0)!.id!);
      const { verificationId } = verification;
      const newInspection = new Inspection(inspection);
      const index = newInspection?.verifications?.length
        ? newInspection.verifications.findIndex((item) => item.verificationId === this.verificationId)
        : -1;

      const newVerifications = newInspection.verifications?.filter((v) => v.verificationId !== verificationId);

      if (index === -1) {
        newInspection.verifications = newVerifications ? [...newVerifications, this] : [this];
      } else {
        newInspection.verifications = newVerifications ? [
          ...newVerifications.slice(0, index),
          this,
          ...newVerifications.slice(index)
        ] : [this];
      }
      await createTransaction(transaction, newInspection);
    }
  }

  async removeFile(
    token: string,
    flogistixId: string,
    file: AirMethaneFile,
    fileVersion: number,
    inspectionId: string
  ) {
    if (flogistixId && file?.id) {
      const queueId = `${flogistixId}->verification->${this.verificationId}`;

      const deleteTransaction = {
        queueId,
        request: {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          endpoint: `https://${urlPrefix}files.api.axil.ai/files/${file?.id}`,
          payload: {},
          version: `${fileVersion}`,
          fileUpload: false
        }
      } as Transaction;

      this.file = undefined;

      await createTransaction(deleteTransaction);

      this.detachFile(token, flogistixId, inspectionId);
    }
  }

  async detachFile(
    token: string,
    flogistixId: string,
    inspectionId: string
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { pendingFiles, ...verificationObject } = this;

    const removalTransaction = {
      queueId: `${flogistixId}->verification->${this.verificationId}`,
      inspectionId,
      request: {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: {
          body: { ...verificationObject },
          pendingFiles: [],
          uploadedFiles: null,
          inspectionId
        },
        fileUpload: true,
        endpoint: `${API_INSPECTION}/${inspectionId}/patchVerification`
      }
    } as unknown as Transaction;

    this.file = undefined;

    await createTransaction(removalTransaction);
  }

  getSubmissionReadiness() {
    return (
      this.verificationId !== undefined
      && this.file?.id !== undefined
      && this.altitude !== undefined
    );
  }

  getVerificationCompletionPercentage() {
    const totalFields = 3;
    const existingFields = [
      this.file?.id,
      this.altitude,
      this.surveyInstrument?.id
    ].filter((field) => !!field);
    return (existingFields.length / totalFields) * 100;
  }

  getRemainingFields() {
    const remainingFields: string[] = [];
    if (!this.file) remainingFields.push('File');
    if (!this.surveyInstrument) remainingFields.push('Instrument');
    if (!this.altitude) remainingFields.push('Altitude');
    return remainingFields;
  }
}
