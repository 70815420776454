import {
  useContext, useEffect, useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchBar, Modal, Header } from '@flogistix/flo-ui';
import { getInspectorById, getOrgById, timestampToYMD } from '../../InspectionLayout/shared/utils';
import { getScheduledReports, cronToInterval, deleteScheduledReport } from '../../../services/airMethaneReportingAPI';
import { GlobalContext } from '../../../context/GlobalContext';
import { Organization } from '../../../dexie/db';

import './ScheduledReports.scss';
import MenuTooltip from '../../../components/ToolTips';

const reportEnums = Object.freeze({
  'airmethane-leak-report': 'Leak Report',
  'airmethane-daily-leak-report': 'Daily Leak Report',
  'airmethane-monthly-overview': 'Monthly Overview Report'
});

export interface Report {
  application: string
  cronUtc: string
  email: string
  lastRunUtc: number
  options: {
    inspectorId: string
    orgId: number
    reportName: string
    siteId: string
  }
  reportName: string
  type: string
  userReportName: string
}

export interface ReportData {
  reportName: string
  orgName: string
  siteName: string
  inspectorName: string
  lastRunUtc: string
  reportCycle: string
  reportType: string
  report: Report
}

const ScheduledReports = () => {
  const {
    token, orgs, inspectors, triggerGlobalNotification, loading
  } = useContext(GlobalContext);

  const [scheduledReports, setScheduledReports] = useState<ReportData[]>([]);
  const [filteredReports, setFilteredReports] = useState<ReportData[]>([]);
  const [reportsLoading, setReportsLoading] = useState(true);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reportToDelete, setReportToDelete] = useState<ReportData | undefined>(undefined);
  const [modalLoading, setModalLoading] = useState(false);

  const navigate = useNavigate();

  const triggerSuccessNotification = () => {
    triggerGlobalNotification({
      type: 'success',
      message: 'Your report was successfully Deleted..',
      title: 'Successfully deleted report',
      fixedWidth: '70%'
    });
  };

  const triggerErrorNotification = (error: Error) => {
    triggerGlobalNotification({
      type: 'error',
      message: `There was a problem deleting your report. Please try again. ${error.message}`,
      title: 'Report deletion failed',
      fixedWidth: '70%'
    });
  };

  const handleDelete = async (reportName: string) => {
    deleteScheduledReport(reportName, token).then(() => {
      setShowDeleteModal(false);
      setModalLoading(false);
      setScheduledReports(scheduledReports.filter(
        (r: ReportData) => encodeURIComponent(r.report.userReportName) !== reportName
      ));
      setFilteredReports(filteredReports.filter(
        (r: ReportData) => encodeURIComponent(r.report.userReportName) !== reportName
      ));
      triggerSuccessNotification();
    }).catch((error) => {
      setShowDeleteModal(false);
      setModalLoading(false);
      triggerErrorNotification(error);
    });
  };

  const generateTableData = (reports: Report[]) => reports.map((scheduledReport) => {
    const org = getOrgById(scheduledReport.options.orgId, orgs) as Organization;
    const site = org.sites ? org.sites.find((s) => s.siteId.toString() === scheduledReport.options.siteId) : undefined;
    const inspector = getInspectorById(scheduledReport.options.inspectorId, inspectors);
    const reportData: ReportData = {
      reportName: scheduledReport.options.reportName,
      orgName: org.orgName,
      siteName: site?.siteName || 'ALL',
      inspectorName: inspector || 'ALL',
      lastRunUtc: scheduledReport.lastRunUtc ? timestampToYMD(scheduledReport.lastRunUtc) : 'Not Run Yet',
      reportCycle: cronToInterval(scheduledReport.cronUtc),
      reportType: reportEnums[scheduledReport.reportName as keyof typeof reportEnums] || scheduledReport.reportName,
      report: scheduledReport
    };
    return reportData;
  });

  useEffect(() => {
    if (!token) return;
    const fetchScheduledReports = async () => {
      try {
        const response = await getScheduledReports(token);
        setScheduledReports(generateTableData(response.reports));
        setFilteredReports(generateTableData(response.reports));
        setReportsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchScheduledReports();
  }, [token, loading]);

  function handleFilterReports(value: string) {
    if (value === '') {
      setFilteredReports(scheduledReports);
      return;
    }
    setFilteredReports(scheduledReports.filter(
      (singleReport) => singleReport.report.options.reportName.toLowerCase().includes(value.toLowerCase())
                  || singleReport.orgName.toLowerCase().includes(value.toLowerCase())
                  || singleReport.siteName.toLowerCase().includes(value.toLowerCase())
                  || singleReport.inspectorName.toLowerCase().includes(value.toLowerCase())
                  || singleReport.lastRunUtc.toLowerCase().includes(value.toLowerCase())
                  || singleReport.reportCycle.toLowerCase().includes(value.toLowerCase())
                  || singleReport.reportType.toLowerCase().includes(value.toLowerCase())
    ));
  }

  return (
    <div className="reporting-wrapper">
      {reportsLoading || loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {reportToDelete !== undefined && (
            <Modal
              title="Delete report"
              isOpen={showDeleteModal}
              variant="delete"
              onClose={() => setShowDeleteModal(false)}
              subtitle="Are you sure you want to delete the following report?"
              actions={{
                onCancel: () => setShowDeleteModal(false),
                onConfirm: () => {
                  setModalLoading(true);
                  handleDelete(encodeURIComponent(reportToDelete.report.userReportName));
                },
                loading: modalLoading
              }}
            >
              <div style={{ display: 'flex', gap: '8px', flexDirection: 'row' }}>
                <Header headerSize="h6" fontStyle="regular" className="modal-info--label">{'Customer: '}</Header>
                <Header headerSize="h6" fontStyle="regular" className="modal-info--data">
                  {reportToDelete.orgName}
                </Header>
              </div>
              <div style={{ display: 'flex', gap: '8px', flexDirection: 'row' }}>
                <Header headerSize="h6" fontStyle="regular" className="modal-info--label">{'Report Cycle: '}</Header>
                <Header headerSize="h6" fontStyle="regular" className="modal-info--data">
                  {reportToDelete.reportCycle}
                </Header>
              </div>
              <div style={{ display: 'flex', gap: '8px', flexDirection: 'row' }}>
                <Header headerSize="h6" fontStyle="regular" className="modal-info--label">{'Report Type: '}</Header>
                <Header headerSize="h6" fontStyle="regular" className="modal-info--data">
                  {reportToDelete.reportType}
                </Header>
              </div>
            </Modal>
          )}
          <SearchBar
            placeholder="Search for a report"
            onChange={(event) => { handleFilterReports(event.target.value); }}
          />
          <table className="scheduled-reports-table">
            <thead className="scheduled-reports-table--title">
              <tr><td>All Scheduled Reports</td></tr>
            </thead>
            <thead className="scheduled-reports-table--header">
              <tr>
                <th className="col-1">Report Name</th>
                <th className="col-2">Customer</th>
                <th className="col-3">Site</th>
                <th className="col-4">Inspector</th>
                <th className="col-5">Last Report Date</th>
                <th className="col-6">Report Cycle</th>
                <th className="col-7">Report Type</th>
                <th className="col-8">{' '}</th>
              </tr>
            </thead>
            <tbody className="scheduled-reports-table--body">
              {filteredReports.map((reportData, index) => (
              // eslint-disable-next-line react/no-array-index-key
                <tr key={index} className={`${index % 2 === 0 ? 'even' : 'odd'}`}>
                  <td className="col-1">{reportData.reportName}</td>
                  <td className="col-2">{reportData.orgName}</td>
                  <td className="col-3">{reportData.siteName}</td>
                  <td className="col-4">{reportData.inspectorName}</td>
                  <td className="col-5">{reportData.lastRunUtc}</td>
                  <td className="col-6">{reportData.reportCycle}</td>
                  <td className="col-7">{reportData.reportType}</td>
                  <td className="col-8">
                    <MenuTooltip options={[
                      {
                        label: 'Delete',
                        iconName: 'Trash',
                        action: () => { setReportToDelete(reportData); setShowDeleteModal(true); }
                      }, {
                        label: 'Edit',
                        iconName: 'Edit',
                        action: () => navigate('/reports/edit-scheduled-report', { state: reportData })
                      }
                    ]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default ScheduledReports;
