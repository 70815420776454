import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Input, Dropdown } from '@flogistix/flo-ui';

import { InspectionType } from '../../../../classes/enums';
import { InspectionContext } from '../../../../context/InspectionContext';
import { GlobalContext } from '../../../../context/GlobalContext';
import { generateFlogistixId } from '../../shared/utils';
import Datepicker from '../../../../components/Datepicker/Datepicker';
import { formatDate } from '../../../../shared/utils';
import { DropdownOptionType } from '../../shared/typings';
import StatusDropzone from '../../../../components/FileDropzone/StatusDropzone/StatusDropzone';
import AirMethaneFile, { UploadFile } from '../../../../classes/airmethane-file';
import { fetchFilesForInspection } from '../../../../services/airmethaneApi';
import { addOrUpdatePendingFiles, removePendingFile } from '../../../../dexie/operations';

const PartOne = () => {
  const { user } = useAuth0();
  const {
    inspection, token, setCompletedSectionsOnChange, hasAllRequiredFields
  } = useContext(InspectionContext);
  const { orgs } = useContext(GlobalContext);

  const customerOptions: DropdownOptionType[] = orgs.map((org: { orgName: string, netsuiteId: string }) => ({
    value: `${org.netsuiteId}`,
    label: `${org.orgName}`
  }));

  const [inspectionType, setInspectionType] = useState(
    inspection?.inspectionType || InspectionType.FACILITY
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [searchCustomer, setSearchCustomer] = useState('');
  const [searchSite, setSearchSite] = useState('');
  const [inspectionDate, setInspectionDate] = useState(
    inspection?.inspectionDate ? formatDate(inspection.inspectionDate) : ''
  );
  const [customer, setCustomer] = useState<DropdownOptionType | null>(
    inspection?.org
      ? { value: `${inspection.org.id}`, label: inspection.org.name }
      : null
  );
  const [siteOptions, setSiteOptions] = useState<DropdownOptionType[]>([]);
  const [site, setSite] = useState<DropdownOptionType | null>(
    inspection?.site
      ? { value: `${inspection.site.id}`, label: inspection.site.name }
      : null
  );
  const [inspectionState, setInspectionState] = useState(inspection?.state || '');
  const [inspectionCounty, setInspectionCounty] = useState(inspection?.county || '');
  const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([]);
  const [inspectionFiles, setInspectionFiles] = useState<AirMethaneFile[]>(inspection?.files || []);

  const handleInspectionTypeChange = (selectedOption: DropdownOptionType) => {
    if (selectedOption) {
      const value = selectedOption.value as InspectionType;
      inspection!.updateInspectionType(token, value).then(() => {
        setCompletedSectionsOnChange();
        setInspectionType(value);
      });
    }
  };

  const handleInspectionDateChange = (dateString: string) => {
    const inputDate = new Date(dateString);
    if (Number.isNaN(inputDate.getTime())) {
      return inspection!.removeInspectionDate(token).then(() => {
        setInspectionDate('');
      });
    }
    return inspection!.updateInspectionDate(token, inputDate.toISOString()).then(() => {
      setCompletedSectionsOnChange();
      setInspectionDate(inputDate.toISOString());
    });
  };

  const handleCustomerChange = async (selectedOption: DropdownOptionType) => {
    if (selectedOption.value === customer?.value) return;
    if (selectedOption) {
      const { value } = selectedOption;
      const selectedCustomer = orgs.find(
        (org: { orgName: string; netsuiteId: string }) => `${org.netsuiteId}` === value
      );
      if (selectedCustomer) {
        const customerPayload = {
          id: selectedCustomer.netsuiteId,
          name: selectedCustomer.orgName
        };
        const emptySitePayload = { id: '', name: '' };

        if (site?.value) await setSite({ value: '', label: '' });
        await setCustomer(selectedOption);
        if (site?.value) await inspection?.updateSite(token, emptySitePayload);
        await inspection?.updateOrg(token, customerPayload);
        setCompletedSectionsOnChange();
      }
    }
  };

  const handleSiteChange = async (selectedOption: DropdownOptionType) => {
    if (selectedOption) {
      const { value } = selectedOption;
      const selectedSite = siteOptions.find((option) => option.value === value);

      if (selectedSite) {
        const sitePayload = {
          id: selectedSite.value as string,
          name: selectedSite.label
        };
        await setSite(selectedOption);
        await inspection?.updateSite(token, sitePayload);
        setCompletedSectionsOnChange();
      }
    }
  };

  const handleInspectionStateChange = (event: React.FocusEvent<HTMLInputElement>) => {
    const state = event.target.value as string;
    if (!state.length) {
      inspection!.removeState(token);
    } else if (!(state === inspection!.state)) {
      inspection!.updateInspectionState(token, state);
    }
    setCompletedSectionsOnChange();
    setInspectionState(state);
  };

  const handleInspectionCountyChange = (event: React.FocusEvent<HTMLInputElement>) => {
    const county = event.target.value as string;
    if (!county.length) {
      inspection!.removeCounty(token);
    } else if (!(county === inspection!.county)) {
      inspection!.updateInspectionCounty(token, county);
    }
    setCompletedSectionsOnChange();
    setInspectionCounty(county);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
    files: UploadFile[]
  ) => {
    try {
      await addOrUpdatePendingFiles(files, inspection?.id as string);
      await inspection?.addFiles(token, inspection?.flogistixId as string, inspection!.id as string, files);
      setUploadFiles((prev) => [...prev, ...files]);
    } catch (error) {
      console.error('Error adding files:', error);
    }
  };

  const handleRemoveFile = async (fileId?: string) => {
    if (!fileId) return;

    const fileInUploadFiles = uploadFiles.find((file) => file.id === fileId);
    const fileInInspectionFiles = inspectionFiles.find((file) => file.id === fileId);

    const fileToRemove = fileInUploadFiles
      || fileInInspectionFiles;

    const flogistixId = inspection?.flogistixId;

    if (!flogistixId) return;

    if (fileToRemove) {
      try {
        await inspection?.removeFile(token, flogistixId, fileToRemove.id as string);
        await removePendingFile(inspection?.id as string, fileToRemove.id as string);

        await setUploadFiles((prev) => {
          const updatedUploadFiles = prev.filter((file) => file.id !== fileId);
          return updatedUploadFiles;
        });

        await setInspectionFiles((prev) => {
          const updatedInspectionFiles = prev.filter((file) => file.id !== fileId);
          return updatedInspectionFiles;
        });
      } catch (error) {
        console.error('Error removing file:', error);
      }
    }
  };

  const fetchInspectionFiles = async () => {
    if (inspection?.id && token) {
      const files = await fetchFilesForInspection(inspection.id, token);
      setInspectionFiles(files);
    }
  };

  useEffect(() => {
    if (customer?.value) {
      const sitesOnOrg = orgs.find(
        (org: { orgName: string, netsuiteId: string }) => `${org.netsuiteId}` === customer?.value
      )?.sites;
      const formattedSiteOptions = sitesOnOrg?.map((siteOnOrg: { siteName: string, netsuiteId: string }) => ({
        value: `${siteOnOrg.netsuiteId}`,
        label: `${siteOnOrg.siteName}`
      }));
      setSiteOptions(formattedSiteOptions || []);
    }
    fetchInspectionFiles();
  }, []);

  useEffect(() => {
    const sitesOnOrg = orgs.find(
      (org: { orgName: string, netsuiteId: string }) => `${org.netsuiteId}` === customer?.value
    )?.sites;
    const formattedSiteOptions = sitesOnOrg?.map((siteOnOrg: { siteName: string, netsuiteId: string }) => ({
      value: `${siteOnOrg.netsuiteId}`,
      label: `${siteOnOrg.siteName}`
    }));
    setSiteOptions(formattedSiteOptions || []);
  }, [customer]);

  useEffect(() => {
    if (!inspection?.flogistixId && hasAllRequiredFields) {
      const newFlogistixId = generateFlogistixId(user?.name ?? 'UNKN');
      inspection!.updateFlogistixId(newFlogistixId).then(() => {
        inspection!.createInspection(token);
      });
    }
  }, [hasAllRequiredFields, inspection, token, user?.name]);

  useEffect(() => {
    const channel = new BroadcastChannel('upload-status-channel');

    channel.onmessage = async (event) => {
      if (event.data.type === 'UPLOAD_STATUS') {
        await fetchInspectionFiles();
      }
    };

    return () => {
      channel.close();
    };
  }, []);

  if (!customerOptions.length) return <h1>Loading...</h1>;

  return (
    <>
      <h1 className="page-details__header">Site details</h1>
      <h4 className="part">Part 1 of 2</h4>
      <label className="label">Inspection type</label>
      <Dropdown
        id="inspection-type"
        value={inspectionType}
        onSelect={handleInspectionTypeChange}
        className="input__select"
        placeholder="Select inspection type"
        options={Object.values(InspectionType)
          .filter((type) => !searchTerm || type.toLowerCase().includes(searchTerm.toLowerCase()))
          .map((type) => ({ value: type, label: type }))}
        searchValue={searchTerm}
        onSearchChange={(value) => setSearchTerm(value)}
        fixedWidth="100%"
      />
      <label className="label">Inspection date</label>
      <Datepicker id="inspection-date" selected={inspectionDate || ''} onChange={handleInspectionDateChange} />
      <label className="label">Customer</label>
      <Dropdown
        id="customer"
        value={customer?.label ?? ''}
        onSelect={handleCustomerChange}
        className="input__select"
        options={customerOptions.filter(
          (option) => !searchCustomer || option.label.toLowerCase().includes(searchCustomer.toLowerCase())
        )}
        placeholder="Select a customer"
        searchValue={searchCustomer}
        onSearchChange={(value) => setSearchCustomer(value)}
        fixedWidth="100%"
      />
      <label className="label">Site name</label>
      <Dropdown
        id="site-name"
        value={site?.label ?? ''}
        onSelect={handleSiteChange}
        className="input__select"
        options={siteOptions.filter((option) => option.label.toLowerCase().includes(searchSite.toLowerCase()))}
        placeholder="Dependent on customer"
        searchValue={searchSite}
        onSearchChange={(value) => setSearchSite(value)}
        fixedWidth="100%"
      />
      <label className="label">State</label>
      <Input
        id="state"
        type="text"
        name="state"
        defaultValue={inspectionState}
        onBlur={handleInspectionStateChange}
        placeholder="Dependent on site"
        className="input__text"
        fixedWidth="100%"
      />
      <label className="label">County</label>
      <Input
        id="county"
        type="text"
        name="county"
        defaultValue={inspectionCounty}
        onBlur={handleInspectionCountyChange}
        placeholder="Dependent on site"
        className="input__text"
        fixedWidth="100%"
      />
      <label className="label">Upload files</label>
      <label className="sub-label">Files should be .jpg, .png, .heic, .mp4, .pdf or .mov</label>
      <StatusDropzone
        onFileChange={handleFileChange}
        acceptedTypes=".png, .jpg, .jpeg, .heic, .mp4, .mov, .avi, .pdf"
        removeHandler={handleRemoveFile}
        fileIds={
          [
            ...(inspectionFiles ?? [])
          ]?.map((file) => file.id)?.filter((id = '') => !!id) as string[] ?? []
        }
        disabled={!hasAllRequiredFields}
      />
      <span className="spacer" />
    </>
  );
};

export default PartOne;
