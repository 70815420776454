import React, {
  useState, useEffect, useContext, useRef
} from 'react';
import { Tabs } from 'antd';
import { FaCircle } from 'react-icons/fa';
import { RiAddFill } from 'react-icons/ri';
import { Button } from '@flogistix/flo-ui';
import { useNavigate } from 'react-router-dom';
import { IoCloseOutline } from 'react-icons/io5';

import ProcessTable from '../../components/ProcessTable/ProcessTable';
import CopyToast from './CopyToast';
import * as operations from '../../dexie/operations';
import { TAB_NAMES } from '../../shared/constants';
import {
  queueColumns,
  processingColumns,
  retryingColumns,
  completedColumns
} from './conditionalColumns';
import './UploadManagement.scss';
import { TransactionTableData } from './uploadManagementTypes';
import { GlobalContext } from '../../context/GlobalContext';

const { TabPane } = Tabs;

const UploadManagement: React.FC = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { is_admin } = useContext(GlobalContext);
  const [activeTab, setActiveTab] = useState(TAB_NAMES.QUEUED);
  const [tableKey, setTableKey] = useState(0);
  const [allData, setAllData] = useState<TransactionTableData[]>([]);
  const [isToastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isTooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [tooltipContent, setTooltipContent] = useState<React.ReactNode | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const closeTooltip = () => {
    setTooltipVisible(false);
    setTooltipContent(null);
  };

  const showTooltip = (content: React.ReactNode, position: { top: number, left: number }) => {
    setTooltipContent(
      <div ref={tooltipRef} className="tooltip" style={{ top: position.top, left: position.left }}>
        {content}
        <button
          type="button"
          onClick={closeTooltip}
          className="tooltip-button close-button"
          aria-label="Close"
        >
          <IoCloseOutline size="24" />
        </button>
      </div>
    );
    setTooltipVisible(true);
  };

  const getTransactions = async () => {
    const transactions = await operations.getAllTransactions();
    const dataItems: TransactionTableData[] = transactions.map(
      (transaction) => ({
        queueId: transaction.queueId,
        request: {
          method: transaction.request.method,
          headers: transaction.request.headers,
          payload: transaction.request.payload,
          endpoint: transaction.request.endpoint
        },
        status: transaction.status,
        attempts: transaction.attempts || 0,
        createdAt: transaction.createdAt,
        id: transaction.id,
        updatedAt: transaction.updatedAt,
        processingStartedAt: transaction.processingStartedAt,
        formattedRequest: JSON.stringify(transaction.request, null, 2),
        formattedResponse: JSON.stringify(
          transaction || '{}',
          null,
          2
        )
      })
    );

    setAllData(dataItems);
  };

  const failedDataExists = allData.some((item) => item.status === 'failed');

  const handleCloseToast = () => {
    setToastVisible(false);
  };

  const copyToClipboardWithToast = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setToastMessage('Copied to clipboard');
        setToastVisible(true);
        setTimeout(() => setToastVisible(false), 5000);
      },
      (err) => {
        console.error('Failed to copy text: ', err);
        setToastMessage('Failed to copy text.');
        setToastVisible(true);
        setTimeout(() => setToastVisible(false), 5000);
      }
    );
  };

  const handleTabChange = (activeKey: string): void => {
    setActiveTab(activeKey);
    setTableKey((prevKey) => prevKey + 1);
  };

  const getColumns = () => {
    switch (activeTab) {
      case TAB_NAMES.QUEUED:
        return queueColumns(copyToClipboardWithToast, showTooltip);
      case TAB_NAMES.PROCESSING:
        return processingColumns(copyToClipboardWithToast, showTooltip);
      case TAB_NAMES.RETRYING:
      case TAB_NAMES.FAILED:
        return retryingColumns(copyToClipboardWithToast, showTooltip);
      case TAB_NAMES.COMPLETED:
        return completedColumns(copyToClipboardWithToast, showTooltip);
      default:
        return queueColumns(copyToClipboardWithToast, showTooltip);
    }
  };

  const getFilteredData = (): TransactionTableData[] => allData.filter(
    (item) => item.status === activeTab.toLowerCase()
  );

  const capitalizeFirstLetter = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        closeTooltip();
      }
    };

    if (isTooltipVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isTooltipVisible]);

  useEffect(() => {
    const channel = new BroadcastChannel('transaction-channel');

    channel.onmessage = (event) => {
      if (event.data.type === 'TRANSACTIONS_UPDATED') {
        getTransactions();
      }
    };

    return () => channel.close();
  }, []);

  useEffect(() => {
    if (!allData.length) getTransactions();
  }, []);

  return (
    <>
      <section className="button-wrapper">
        <div className="upload-header">Transactions</div>
        {is_admin && (
          <div className="button-wrapper">
            <Button
              type="button"
              data-cy="CreateInspection"
              onClick={() => {
                navigate('/inspections');
              }}
            >
              <RiAddFill
                size={16}
                style={{ marginRight: '4px', verticalAlign: 'text-top' }}
              />
              Create new inspection
            </Button>
          </div>
        )}
      </section>
      <section className="styled-section">
        <Tabs
          className="custom-tab-style"
          defaultActiveKey={TAB_NAMES.QUEUED}
          onChange={handleTabChange}
        >
          {Object.entries(TAB_NAMES).map(([key, name]) => (
            <TabPane
              tab={
                name === 'failed' && failedDataExists ? (
                  <>
                    {capitalizeFirstLetter(name)}
                    <FaCircle size="8px" className="circle-icon" />
                  </>
                ) : (
                  capitalizeFirstLetter(name)
                )
              }
              key={key.toLowerCase()}
            >
              <div className="table-container">
                <ProcessTable
                  key={tableKey}
                  data={getFilteredData()}
                  columns={getColumns()}
                />
                {isToastVisible && (
                  <CopyToast
                    message={toastMessage}
                    onClose={handleCloseToast}
                  />
                )}
                {isTooltipVisible && tooltipContent}
              </div>
            </TabPane>
          ))}
        </Tabs>
      </section>
    </>
  );
};

export default UploadManagement;
