import React, {
  useState, useCallback, useContext
} from 'react';
import './rangePicker.scss';
import { InspectionContext } from '../../context/InspectionContext';

const RangerPicker: React.FC = () => {
  const { inspection, token, setCompletedSectionsOnChange } = useContext(InspectionContext) || {};

  const [localStartTime, setLocalStartTime] = useState<string>(
    inspection?.startTime ? inspection.startTime.slice(0, 5) : ''
  );
  const [localEndTime, setLocalEndTime] = useState<string>(
    inspection?.endTime ? inspection.endTime.slice(0, 5) : ''
  );

  const handleStartTimeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = event.target.value;
    setLocalStartTime(newTime);

    if (inspection && token) {
      try {
        if (newTime.length === 5) {
          const formattedTime = `${newTime}:00`;
          if (inspection.startTime !== formattedTime) {
            inspection.updateStartTime(token, formattedTime);
          }
        } else if (newTime.length === 0) {
          inspection.removeStartTime(token);
        }
        setCompletedSectionsOnChange();
      } catch (error) {
        console.error('Error updating start time:', error);
      }
    }
  }, [inspection, token, setCompletedSectionsOnChange]);

  const handleEndTimeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = event.target.value;
    setLocalEndTime(newTime);

    if (inspection && token) {
      try {
        if (newTime.length === 5) {
          const formattedTime = `${newTime}:00`;
          if (inspection.endTime !== formattedTime) {
            inspection.updateEndTime(token, formattedTime);
          }
        } else if (newTime.length === 0) {
          inspection.removeEndTime(token);
        }
        setCompletedSectionsOnChange();
      } catch (error) {
        console.error('Error updating end time:', error);
      }
    }
  }, [inspection, token, setCompletedSectionsOnChange]);

  const handleStartTimeBlur = useCallback(() => {
    if (localStartTime.length !== 5 && localStartTime.length !== 0) {
      setLocalStartTime('00:00');
      if (inspection && token) {
        try {
          inspection.updateStartTime(token, '00:00:00');
          setCompletedSectionsOnChange();
        } catch (error) {
          console.error('Error updating start time on blur:', error);
        }
      }
    }
  }, [localStartTime, inspection, token, setCompletedSectionsOnChange]);

  const handleEndTimeBlur = useCallback(() => {
    if (localEndTime.length !== 5 && localEndTime.length !== 0) {
      setLocalEndTime('00:00');
      if (inspection && token) {
        try {
          inspection.updateEndTime(token, '00:00:00');
          setCompletedSectionsOnChange();
        } catch (error) {
          console.error('Error updating end time on blur:', error);
        }
      }
    }
  }, [localEndTime, inspection, token, setCompletedSectionsOnChange]);

  return (
    <div className="time-picker-container">
      <div className="time-picker-icon">
        <input
          type="time"
          className="time-picker"
          value={localStartTime}
          onChange={handleStartTimeChange}
          onBlur={handleStartTimeBlur}
        />
      </div>

      <div className="time-picker-icon time-picker-icon-end">
        <input
          type="time"
          className="time-picker"
          value={localEndTime}
          onChange={handleEndTimeChange}
          onBlur={handleEndTimeBlur}
        />
      </div>
    </div>
  );
};

export default RangerPicker;
