import { Icon } from '@flogistix/flo-ui';
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import './styles.scss';

interface MenuOption {
  label: string;
  iconName: 'Trash' | 'Edit' | 'DotsVertical';
  action: () => void;
}

const MenuTooltip = ({ options }: { options: MenuOption[] }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [coords, setCoords] = useState({ top: 0, left: 0 });
  const menuRef = useRef<HTMLDivElement>(null);
  const antSectionRef = useRef<HTMLElement | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent) => {
    const rect = (e.target as HTMLButtonElement).getBoundingClientRect();
    setCoords({
      top: rect.top - 90,
      left: rect.left - 230
    });
    setIsVisible(!isVisible);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);

  useEffect(() => {
    const handleResize = () => {
      setIsVisible(false);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    antSectionRef.current = document.querySelector('.scrollable-section');

    const handleScroll = () => {
      setIsVisible(false);
    };

    if (antSectionRef.current) {
      antSectionRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (antSectionRef.current) {
        antSectionRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleOptionClick = (action: () => void) => {
    setIsVisible(false);
    action();
  };

  return (
    <>
      <div className="reporting-tooltip-wrapper">
        <button
          type="button"
          onClick={handleClick}
          onKeyDown={(e) => { if (e.key === 'Enter') handleClick(e); }}
          aria-label="Toggle Tooltip"
        >
          <Icon iconName="DotsVertical" />
        </button>
      </div>
      {isVisible
        && ReactDOM.createPortal(
          <div
            ref={menuRef}
            className="reporting-tooltip"
            style={{
              position: 'fixed',
              top: coords.top,
              left: coords.left
            }}
          >
            {options.map((option) => (
              <button
                type="button"
                key={option.label}
                onClick={() => handleOptionClick(option.action)}
                onKeyDown={(e) => { if (e.key === 'Enter') handleOptionClick(option.action); }}
                className="reporting-tooltip--button"
              >
                <Icon iconName={option.iconName} width={16} height={16} />
                {option.label}
              </button>
            ))}
          </div>,
          document.body
        )}
    </>
  );
};

export default MenuTooltip;
