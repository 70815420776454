import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { GlobalContext } from '../../context/GlobalContext';
import SkeletonTable from '../../components/Table/SkeletonTable';
import InspectionTable from './InspectionTable';
import { StyledDiv } from './styled-components';

const Inspections = () => {
  const params = useParams();
  const history = useNavigate();
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    inspections, loading, user_sites, user_organizations
  } = useContext(GlobalContext);
  const [filteredInspections, setFilteredInspections] = useState(inspections);
  const [netsuiteSite, setNetsuiteSite] = useState(null);
  const [netsuiteOrganization, setNetsuiteOrganization] = useState(null);
  const shouldRender = !loading && !!netsuiteSite && !!netsuiteOrganization;

  const goToOrgList = () => {
    setNetsuiteSite(null);
    history(`/orgs/${netsuiteOrganization.netsuiteId}`);
  };

  const goToStart = () => {
    setFilteredInspections(inspections);
    setNetsuiteSite(null);
    setNetsuiteOrganization(null);
    history('/');
  };

  useEffect(() => {
    if (!loading) {
      const customerId = parseInt(params.customerId, 10);
      const customerLocationId = parseInt(params.id, 10);

      const organization = user_organizations.find((org) => org.netsuiteId === customerId);
      const site = user_sites.find((siteArg) => siteArg.netsuiteId === customerLocationId);

      setNetsuiteOrganization(organization || null);
      setNetsuiteSite(site || null);

      if (organization && site) {
        const filtered = inspections?.filter(
          (i) => parseInt(i.site.id, 10) === customerLocationId
            && i.status?.code?.toLowerCase() === 'complete'
        );
        setFilteredInspections(filtered || []);
      } else {
        setFilteredInspections([]);
      }
    }
  }, [params, inspections, loading, user_organizations, user_sites]);

  return shouldRender ? (
    <StyledDiv>
      <InspectionTable
        inspections={filteredInspections}
        user_organization={netsuiteOrganization}
        user_site={netsuiteSite}
        onOrgBreadcrumbClick={goToOrgList}
        onInspectionBreadcrumbClick={goToStart}
        history={history}
      />
    </StyledDiv>
  ) : (
    <StyledDiv>
      <SkeletonTable isInspections />
    </StyledDiv>
  );
};

export default Inspections;
