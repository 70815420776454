import React, { useState, useEffect, useRef } from 'react';
import { IoCopyOutline } from 'react-icons/io5';

import { global } from '../../shared/colors';
import './UploadManagement.scss';
import CopyToast from './CopyToast';

interface ResponseCellProps {
  formattedResponse: string;
  showTooltip: (content: React.ReactNode, position: { top: number, left: number }) => void;
  hideTooltip?: () => void;
}

interface ResponseObject {
  response?: Record<string, string>;
  status?: string;
  attempts?: number;
  createdAt?: string;
  updatedAt?: string | null;
  processingStartedAt?: string;
}

const ResponseCell: React.FC<ResponseCellProps> = ({ formattedResponse, showTooltip, hideTooltip = () => {} }) => {
  const [isToastVisible, setToastVisible] = useState(false);
  const [responseObject, setResponseObject] = useState<ResponseObject | null>(null);
  const [parseError, setParseError] = useState<string | null>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false); // New state to track tooltip visibility

  const defaultStyle = global.Gray4;
  const hoverStyle = global.Gray3;

  useEffect(() => {
    try {
      const parsed = JSON.parse(formattedResponse);
      setResponseObject(parsed);
      setParseError(null);
    } catch (error) {
      console.error('Failed to parse formattedResponse: ', error);
      setParseError('Failed to parse response.');
      setResponseObject(null);
    }
  }, [formattedResponse]);

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(formattedResponse)
      .then(() => {
        setToastVisible(true);
        setTimeout(() => setToastVisible(false), 5000);
        setIsTooltipVisible(false);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  const formatDate = (date: string | null | undefined) => (date ? new Date(date).toLocaleString() : 'N/A');

  const formattedDisplay = responseObject
    && `
    response?:  ${JSON.stringify(responseObject.response, null, 2)}
    status?: ${responseObject.status}
    attempts?: ${responseObject.attempts}
    createdAt?: ${formatDate(responseObject.createdAt)}
    updatedAt?: ${formatDate(responseObject.updatedAt)}
    processingStartedAt?: ${formatDate(responseObject.processingStartedAt)}
    `;

  const toggleTooltip = () => {
    if (isTooltipVisible) {
      hideTooltip();
    } else if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const tooltipHeight = 200;
      const offset = 86;
      const position = {
        top: buttonRect.top - tooltipHeight - offset,
        left: buttonRect.left
      };
      showTooltip(
        <div className="response">
          <h2 className="response-header">Response</h2>
          <p className="response-text">{formattedDisplay}</p>
          <button
            type="button"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleCopyToClipboard}
            className="tooltip-button copy-button"
          >
            <IoCopyOutline
              size="24"
              color={isHovered ? hoverStyle : defaultStyle}
            />
            {isToastVisible && (
              <CopyToast
                message="Request copied to clipboard"
                onClose={() => setToastVisible(false)}
              />
            )}
          </button>
        </div>,
        position
      );
    }
    setIsTooltipVisible(!isTooltipVisible);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      toggleTooltip();
    }
  };

  if (parseError) {
    return (
      <div>
        Error parsing response:
        {parseError}
      </div>
    );
  }

  return (
    <div className="request-cell-container">
      <div
        ref={buttonRef}
        tabIndex={0}
        onClick={toggleTooltip}
        onKeyDown={handleKeyDown}
        className="request-button"
        role="button"
        aria-pressed={false}
      >
        View Response
      </div>
    </div>
  );
};

ResponseCell.defaultProps = {
  hideTooltip: () => {}
};

export default ResponseCell;
